import React from 'react';

const AppClockinSubHeader = () => {
  return (
      <>
        <section id="home" className="effect-section" style={{backgroundColor: '#50167E'}}>
          <img className="position-absolute bottom-0 start-0 pe-none w-px-100 w-px-lg-250"
               src="/img/effect/polygon-03.png" title="" alt="" />
            <img className="position-absolute top-0 end-0 pe-none w-px-100 w-px-lg-150"
                 src="/img/effect/polygon-04.png" title="" alt="" />
              <div className="container position-relative">
                <div className="row min-vh-100 align-items-center py-10 wow fadeInUp" data-wow-duration="0.5s">
                  <div className="col-md-6 py-8 me-auto ">
                    <h1 className="display-4 text-white mb-3">
                      {/*<span className="text-info">WELCOME</span>,<br /> CLOCK-IN WITH APP CLOCKIN</h1>*/}
                      <img src="/img/ims-images/welcome-app-write.png" alt=""/>
                    </h1>
                    <p className="lead text-white-85">
                      The App Clockin is a product created to provide solutions to businesses and organizations by
                      digitally managing, monitoring the attendance register of its staff hereby solving the problem of time theft.
                    </p>
                    <div className="pt-3 d-flex">
                      <a className="me-3" href="#"><img src="/img/effect/playstore.png" title="" alt="" /></a>
                      <a href="#"><img src="/img/effect/apple-store.png" title="" alt="" /></a>
                    </div>
                  </div>
                  <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s">
                    {/*<img className="lg-img-120" src="/img/ims-images/appClock2.png" title="" alt="" />*/}
                    {/*<img className="lg-img-140" src="/img/ims-images/appClock-logo.png" title="" alt="" />*/}
                    <img className="lg-img-140 " src="/img/ims-images/WEB-2122.png" title="" alt="" />
                  </div>
                </div>
              </div>
        </section>
      </>
  );
};

export default AppClockinSubHeader;
