import React from "react";
import Header from "./layouts/Header";
import MetaDataDecorator from "../utilities/MetaDataDecorator";
import SideMenu from "./termsAndConditions/SideMenu";
import "../components/termsAndConditions/Style.css";
import Footer from "./layouts/Footer";
import { NavLink } from "react-router-dom";

const TermsAndConditions = () => {
    return(
        <>
            <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link" logoImageDark="/img/ims-images/IMS-Bluey.png"  logoImageWhite="/img/ims-images/IMS-whitey.png"
                showProduct={true}/>
            <MetaDataDecorator description="Home" title="IMS | Terms and Conditions" />
            <main className="t-and-c-main">
                <section className="body">
                    <div id="tc-intro">
                        <div className="tc-title bg-light-after after-50px mb-4">Terms and Conditions</div>
                        <div className="section-title mb-3">Introduction</div>
                        <div className="section-text">
                        Please read this page carefully. It contains
                         the terms and conditions (the "Terms and Conditions")
                          governing your access to and use of the IntelliEMS
                           Web Sites and the Services (as each are defined below)
                            provided by IntelliEMS Ltd,  or one of its divisions
                             (collectively, "IntelliEMS"). If you do not accept 
                             these Terms and Conditions or you do not meet or 
                             comply with their provisions, you may not use the 
                             IntelliEMS Web Sites or Services. These Terms and 
                             Conditions are effective as of DATE.
                        </div>
                    </div>
                    <div id='tc-bind' className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Binding
                         Agreement</div>
                        <div className="section-text">
                        These Terms and Conditions (as they may be amended from
                         time to time by IntelliEMS), form a binding agreement
                          (the "Agreement") between you and IntelliEMS. Your 
                          access to or use of the IntelliEMS Web Sites or Services 
                          indicates your acceptance of these Terms and Conditions.
                           You are agreeing to use the Sites at your own risk.

                        </div>
                    </div>
                    <div id='tc-defs' className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Definitions</div>
                        <div className="section-text">
                        The following definitions apply to this Agreement:
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">"IntelliEMS Materials"</span> 
                                includes any materials, methodologies, implementation
                                 plans or other intellectual property used during the
                                  provision of Services.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">"IntelliEMS Web Site" or the "Site"
                                 (collectively, the "IntelliEMS Websites" or the "Sites")</span> 
                                 means any website under IntelliEMS's control, whether
                                  partial or otherwise and includes such Site's Content,
                                   IntelliEMS Materials and Services (as applicable in each
                                    context).
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">"Content"</span> 
                                means IntelliEMS's web pages, web forms, programming
                                 (including software code used on the Sites and in the 
                                 Services, including (i) tools, kits, and object libraries,
                                  (ii) all third-party or open source code embedded therein,
                                   and (iii) any upgrades, updates, releases, fixes, 
                                   enhancements or modifications to the foregoing), graphics,
                                    images, design (color combinations and page layout),
                                     text, information, data, resumes stored in various 
                                     commercial databases operated and licensed by IntelliEMS
                                      , data submitted via the Sites by Users and other content
                                       made available through the Sites by IntelliEMS.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">"Document"</span> 
                                refers to any posting to a Site, whether job or resume.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">"Employer"</span> 
                                means a person or entity that is accessing a Site to post
                                 a job or utilizing the Services for any reason related to
                                  the purpose of seeking candidates for employment.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">"Employer Materials"</span> 
                                includes any brochures, emails, sample job postings, web
                                 site content, career fair material, audio, videos, photo
                                 graphs, logos, trademarks, service marks, domain names, 
                                 documents or other materials provided by Employer, if any,
                                  for use in connection with the Services.

                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">"Job Seeker"</span> 
                                means a User who is accessing a Site to search for a job
                                 or in any other capacity except as an Employer.

                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">"Services"</span> 
                                means any services provided by IntelliEMS or its agents 
                                described herein and specified more fully in the Service 
                                Activation Agreement.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">"User"</span> 
                                refers to any individual or entity that uses any aspect 
                                of the Sites.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">"You" or "you"</span> 
                                means the person who (or the entity on behalf of whom you
                                 are acting) that is agreeing to these Terms and Conditions.

                            </div>
                        </div>
                    </div>
                    <div id="tc-intellect" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Intellectual Property 
                        Rights and Acceptable Use of the Sites and Services</div>
                        <div className="section-text">
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">General Use Rules.</span> 
                                The Sites are intended for individuals seeking employment
                                 and for employers or recruiters seeking candidates for 
                                 employment. You may use the Sites only for lawful purposes
                                  within the stated context of IntelliEMS's intended and
                                   acceptable use of the Sites. IntelliEMS is the sole 
                                   interpreter of the Sites' intended and acceptable use.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">IntelliEMS Intellectual Property Rights.</span> 
                                The Sites, the IntelliEMS Materials and all right, title
                                 and interest in and to the Sites and IntelliEMS Materials
                                  are the sole property of IntelliEMS or its licensors, 
                                  and are protected by Laws of the Federal Republic of 
                                  Nigeria, trademark and other laws. Except for the limited
                                   licenses expressly granted to you in these Terms and 
                                   Conditions, IntelliEMS reserves for itself and its licensors
                                    all other rights, title and interest. Without limitation
                                     on the foregoing, you may not reproduce, modify, display,
                                      sell, or distribute the Content or IntelliEMS Materials,
                                       or use them in any other way for public or commercial
                                        purpose. Notwithstanding anything to the contrary 
                                        contained herein, this prohibition includes: (a) copying
                                         or adapting the HTML code used to generate web pages on
                                          the Sites; (b) using or attempting to use engines, 
                                          manual or automated software, tools, devices, agents
                                          , scripts, robots or other means, devices, mechanisms
                                           or processes (including, but not limited to, browsers,
                                            spiders, robots, avatars or intelligent agents) to 
                                            navigate, search, access, "scrape," "crawl," or 
                                            "spider" any web pages or any Services provided on
                                             the Sites other than the search engine and search 
                                             agents available from IntelliEMS on such IntelliEMS
                                              Sites and other than generally available third 
                                              party web browsers (e.g., Internet Explorer, 
                                              Firefox, Safari); and (c) aggregating, copying or 
                                              duplicating in any manner any of the Content or 
                                              information available from any of the IntelliEMS 
                                              Sites, without the express written consent of 
                                              IntelliEMS. The use of the Content on any other 
                                              web site or in a networked computer environment 
                                              for any purpose is strictly prohibited. The 
                                              IntelliEMS Materials are not considered to be works
                                               for hire and you may duplicate such IntelliEMS 
                                               Materials only for the purposes outlined in the
                                                Service Activation Agreement. "IntelliEMS," 
                                                "Personified", the IntelliEMS design logo and 
                                                certain other names or logos are service marks
                                                 or trademarks of IntelliEMS, and all related 
                                                 product and service names, design marks and 
                                                 slogans are the service marks or trademarks of 
                                                 IntelliEMS. In addition, the "look" and "feel"
                                                  of the Sites (including color combinations, 
                                                  button shapes, layout, design and all other 
                                                  graphical elements) are also protected by 
                                                  IntelliEMS's trademarks, service marks and 
                                                  copyrights. Any code that IntelliEMS creates 
                                                  to generate or display the Content or the pages
                                                   making up the Sites is also protected by 
                                                   IntelliEMS's copyright. You must retain all
                                                    copyright, trademark, service mark and other
                                                     proprietary notices contained on the Content
                                                      or IntelliEMS Materials on any authorized
                                                      copy you make of the Content or IntelliEMS
                                                       Materials. All other product and service 
                                                       marks contained on the Sites are the 
                                                       trademarks of their respective owners.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">License to Use by Users who are Job Seekers.</span> 
                                IntelliEMS hereby grants you a limited, terminable, 
                                non-exclusive right to access and use the Sites only for 
                                your personal use seeking employment opportunities for 
                                yourself. This authorizes you to view and download a
                                 single copy of the material on the Sites solely for your
                                  personal, noncommercial use. You agree that you are solely
                                   responsible for the content of any Document you post to
                                    a Site and any consequences arising from such posting.
                                     Your use of the Sites is a privilege. IntelliEMS reserves
                                      the right to suspend or terminate that privilege for 
                                      any reason at any time, in its sole discretion.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">License to Use by Users who are Employers.</span> 
                                IntelliEMS hereby grants you a limited, terminable, non-exclusive
                                 right to access and use the Sites only for your internal 
                                 business use seeking candidates for employment. This authorizes
                                  you to view and download a single copy of the material on the
                                   Sites solely for your personal use directly related to 
                                   searching for and recruiting job prospects. IntelliEMS also
                                    grants you a limited, terminable, non-exclusive license to 
                                    use the IntelliEMS Materials and Services for your internal 
                                    use only. You may not sell, transfer or assign any of the 
                                    Services or your rights to any of the Services provided by
                                    IntelliEMS to any third party without the express written 
                                    authorization of IntelliEMS. You agree that you are solely 
                                    responsible for the content of any Document you post to a 
                                    Site and any consequences arising from such posting. 
                                    IntelliEMS reserves the right to suspend or terminate your
                                     access and use at any time if IntelliEMS determines that
                                      you are in breach of these Terms and Conditions.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">Employer Materials.</span> 
                                Employer represents, warrants and covenants that any 
                                Employer Materials provided by Employer for use in 
                                connection with the Services will not violate any laws or
                                 regulations or third-party proprietary rights, including,
                                  without limitation, copyright, trademark, obscenity, 
                                  rights of publicity or privacy, and defamation laws. 
                                  Employer hereby grants IntelliEMS a non-exclusive, 
                                  worldwide, royalty-free license to use the Employer 
                                  Materials and to hyperlink to Employer's website in 
                                  connection with the Services.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">Use of Aggregate Data.</span> 
                                You understand and agree that IntelliEMS owns and has
                                 the right to collect, extract, compile, synthesize,
                                  and analyze Aggregate Data. IntelliEMS may use such
                                   Aggregate Data for any lawful business purpose without
                                    a duty of accounting to you, provided that the data 
                                    and information is used only in an aggregated and 
                                    anonymized form so that it cannot be identifiable as
                                     relating to you, your Company or to any other Job
                                      Seekers. "Aggregate Data" means de-identified 
                                      aggregated data or information regarding Job Seekers'
                                       educational or career history (including, by way 
                                       of example and not limitation, aggregate data 
                                       relating to Job Seekers' occupation, location, 
                                       salary, education and experience).
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">Other Specific Rules Regarding Site Usage.</span> 
                                You represent, warrant and agree that you (a) are at least
                                 13 years of age or older, and if under the age of 18 or 
                                 the age of majority as that is defined in your jurisdiction,
                                  you will only use the Sites and Services under the 
                                  supervision of a parent, legal guardian, or other responsible
                                   adult; and (b) will not use (or plan, encourage or 
                                   help others to use) the Sites for any purpose or in 
                                   any manner that is prohibited by these Terms and Conditions
                                    or by applicable law. It is your responsibility to 
                                    ensure that your use of the Sites complies with these
                                     Terms and Conditions and all applicable laws.

                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">
                                User Submissions. IntelliEMS welcomes your comments regarding
                                 the Services and the Sites and appreciates hearing from you.
                                  Please note, however, that IntelliEMS does not accept or 
                                  consider creative ideas, suggestions, inventions or materials
                                   other than those which it has specifically requested. 
                                   If you submit feedback on the Services, please be specific
                                    in your comments and do not submit creative ideas, 
                                    inventions, suggestions, or materials. If, despite this
                                     notice, you send IntelliEMS creative suggestions, ideas,
                                      drawings, concepts, inventions, or other information (a 
                                      "User Submission"), you understand and agree that the
                                       User Submission shall become the property of IntelliEMS.
                                        User Submission and any elements contained in User 
                                        Submissions, shall not be subject to any obligation of
                                         confidentiality on IntelliEMS's part, and IntelliEMS 
                                         will not be liable for any use or disclosure of any 
                                         User Submission. IntelliEMS shall exclusively own all
                                          now known or later discovered rights to the User 
                                          Submission and shall be entitled to unrestricted use 
                                          of the User Submission for any purpose whatsoever,
                                           commercial or otherwise, without compensation to you 
                                           or any other person.

                                </span> 
                            </div>
                        </div>
                    </div>
                    <div id="tc-third" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Third
                         Party Providers</div>
                        <div className="section-text">
                        You acknowledge and agree that IntelliEMS may provide 
                        the Services using third party providers, including 
                        subcontractors and consultants (the "Third Party Providers").
                         You agree that, as between IntelliEMS and its Third Party
                         Providers, IntelliEMS will have sole responsibility for 
                         handling all billing and contract negotiations.
                        </div>
                    </div>
                    <div id="tc-disclaimer" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Disclaimers
                        and Limitations on IntelliEMS's Liability</div>
                        <div className="section-text">
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">Allocation of Responsibility.</span> 
                                IntelliEMS assumes no responsibility for Documents
                                 posted by Users and no responsibility for the 
                                 activities, omissions or other conduct of Users. 
                                 IntelliEMS acts as a portal for the online 
                                 distribution and publication of User submitted 
                                 information and has no obligation to screen 
                                 communications or information in advance and is not 
                                 responsible for screening or monitoring Documents
                                  posted by Users. If notified by a User of a Document
                                   which allegedly does not conform to these Terms 
                                   and Conditions, IntelliEMS may investigate the 
                                   allegation and determine in good faith and in its 
                                   sole discretion whether to remove or request the 
                                   removal of such Document. IntelliEMS has no liability
                                    or responsibility to Users for performance or 
                                    nonperformance of such activities. IntelliEMS may 
                                    take any action with respect to User submitted 
                                    information that it deems necessary or appropriate,
                                     in its sole discretion.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">No endorsements by IntelliEMS.</span> 
                                Nothing on the Sites shall be considered an endorsement,
                                 representation or warranty with respect to any User or 
                                 third party, whether in regards to its web site, products,
                                  services, hiring, experience, employment or recruiting 
                                  practices, or otherwise.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">WARRANTY DISCLAIMERS.</span> 
                                Nothing on the Sites shall be considered an endorsement,
                                 representation or warranty with respect to any User or 
                                 third party, whether in regards to its web site, products,
                                  services, hiring, experience, employment or recruiting 
                                  practices, or otherwise.
                                    <div className="section-sub-item">
                                    (a) THE SITES ARE PROVIDED ON AN 'AS IS' BASIS WITHOUT
                                     ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. 
                                     INTELLIEMS, TO THE FULLEST EXTENT PERMITTED BY LAW, 
                                     DISCLAIMS ALL WARRANTIES, INCLUDING, BUT NOT LIMITED 
                                     TO, THE WARRANTIES OF MERCHANTABILITY, TITLE, 
                                     NON-INFRINGEMENT OF THIRD PARTIES' RIGHTS, AND FITNESS
                                      FOR A PARTICULAR PURPOSE. INTELLIEMS MAKES NO WARRANTIES
                                       ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, OR
                                        TIMELINESS OF THE SITES.
                                    </div>
                                    <div className="section-sub-item">
                                    (b) WITHOUT LIMITATION ON THE FOREGOING:
                                        <div className="section-sub-sub-item">
                                        (i) INTELLIEMS DOES NOT WARRANT THAT THE SITES WILL 
                                        OPERATE ERROR-FREE OR THAT THE SITES AND THEIR SERVERS
                                         ARE FREE OF COMPUTER VIRUSES OR OTHER HARMFUL 
                                         MECHANISMS. IF YOUR USE OF THE SITES RESULTS DIRECTLY
                                          OR INDIRECTLY IN THE NEED FOR SERVICING OR REPLACING
                                           EQUIPMENT OR DATA, INTELLIEMS IS NOT RESPONSIBLE FOR
                                            THOSE COSTS.
                                        </div>
                                        <div className="section-sub-sub-item">
                                        (ii) IntelliEMS makes no representations or guarantees 
                                        regarding the truthfulness, accuracy, LEGALITY, 
                                        completeness, timeliness or reliability of any Documents
                                         posted by Users, or of any other form of communication
                                          engaged in by Users. Documents may contain inaccuracies
                                           or typographical errors. You agree that any reliance
                                            on Documents posted by Users, or on any other form 
                                            of communication with Users, will be at your own risk.
                                        </div>
                                        <div className="section-sub-sub-item">
                                        (iii) IntelliEMS makes no representations or guarantees 
                                        regarding the Content of the SiteS, including, but not
                                         limited to, broken links, inaccuracies or typographical 
                                         errors.
                                        </div>
                                        <div className="section-sub-sub-item">
                                        (iv) INTELLIEMS MAKES NO REPRESENTATIONS OR GUARANTEES 
                                        REGARDING THE EFFECTIVENESS OF THE SERVICES OR TIMELINESS
                                         OF THE SERVICES IN MEETING YOUR EMPLOYMENT OBJECTIVES. 
                                         INTELLIEMS DOES NOT GUARANTEE THAT THE SERVICES WILL 
                                         RESULT IN CANDIDATES BEING HIRED, POSITIONS BEING FILLED
                                          OR EMPLOYEES BEING RETAINED, AND IS NOT RESPONSIBLE OR
                                           LIABLE FOR ANY BUSINESS, EMPLOYMENT, HIRING AND/OR SALARY
                                            DECISIONS, FOR WHATEVER REASON MADE, MADE BY YOU.

                                        </div>
                                    </div>
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">DAMAGE LIMITATIONS, ALLOCATIONS OF LIABILITY
                                 AND EQUITABLE RELIEF.</span> 
                                <div className="section-sub-item">
                                (a) YOU ASSUME ALL RESPONSIBILITY AND RISK FOR YOUR USE OF THE 
                                SITES, THE INTERNET GENERALLY, AND THE DOCUMENTS OR EMPLOYER MATERIALS
                                 THAT YOU POST, PROVIDE OR ACCESS AND FOR YOUR CONDUCT ON AND OFF 
                                 THE SITES.
                                </div>
                                <div className="section-sub-item">
                                (b) IN NO EVENT SHALL INTELLIEMS (OR ANY OF ITS OFFICERS, DIRECTORS,
                                 SHAREHOLDERS, EMPLOYEES, SUBSIDIARIES, AFFILIATES, AGENTS OR 
                                 ADVERTISERS), BE LIABLE FOR ANY NON-DIRECT DAMAGES WHATSOEVER 
                                 (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL 
                                 DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA, LOST
                                  EMPLOYMENT OPPORTUNITY, OR BUSINESS INTERRUPTION) RESULTING FROM 
                                  OR ARISING UNDER OR IN CONNECTION WITH SERVICES OR THE USE OR 
                                  ACCESS TO, OR THE INABILITY TO USE OR ACCESS, THE SITES AND/OR 
                                  ANY DOCUMENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY
                                   OTHER LEGAL THEORY, AND WHETHER OR NOT INTELLIEMS IS ADVISED OF 
                                   THE POSSIBILITY OF SUCH DAMAGES.
                                </div>
                                <div className="section-sub-item">
                                (c) Due to the nature of this Agreement, in addition to money 
                                damages, you agree that IntelliEMS will be entitled to equitable 
                                relief upon a breach of this agreement by you.
                                </div>
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold"> User Authentication.</span> 
                                Because User authentication on the Internet is difficult, IntelliEMS
                                 cannot and does not confirm that each User is who they claim to be.
                                  Because IntelliEMS does not and cannot be involved in User-to-User
                                   dealings or control the behavior of participants on the Sites, 
                                   in the event that you have a dispute with one or more Users, you 
                                   release IntelliEMS from claims, demands and damages (actual and
                                    consequential and direct and indirect) of every kind and nature, 
                                    known and unknown, suspected and unsuspected, disclosed and 
                                    undisclosed, arising out of or in any way connected with such 
                                    disputes.
                            </div>
                        </div>      
                    </div>
                    <div id="tc-data" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Data Protection</div>
                        <div className="section-text">
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                Any personal information of any candidate that you obtain through
                                 your use of the Site or any features of the Site or products offered
                                  on the Site (including job applications received from candidates)
                                   must only be used by you in relation to your genuine employment 
                                   and/or recruitment activities.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                Selling or offering services or products (such as learning or 
                                educational courses or tools) to candidates whose personal information
                                 you have obtained through your use of the Site (including job 
                                 applications received from candidates) is considered by INtelliEMS
                                  to be a misuse of candidate data and is prohibited.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                You may not under any circumstances provide any candidate personal
                                 information you have obtained through your use of the Site (including 
                                 job applications received from candidates) to any other party, 
                                 including to any affiliate or related party of yours (unless INtelliEMS
                                  has otherwise consented to this). This restriction on forwarding
                                  personal information applies irrespective of whether you receive direct
                                   financial benefit for doing so.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                INTELLIEMS  is resolute in its determination to prevent the misuse of 
                                candidate data. If  INTELLIEMS  believes that you have misused candidate
                                 data for any reason,  INTELLIEMS  reserves the right to:
                                    <div className="section-sub-item">
                                    (a) immediately suspend or terminate your account, and/or suspend or
                                     terminate the account of any party that has received candidate 
                                     personal information from you in breach of these Terms;
                                    </div>
                                    <div className="section-sub-item">
                                    (b) report any potential contraventions to the relevant authorities;
                                     and/or
                                    </div>
                                    <div className="section-sub-item">
                                    (c) take legal action against you including any number of remedies
                                     provided by law, including the award of monetary damages.
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div id="tc-links" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Links to Other
                         Sites</div>
                        <div className="section-text">
                        IntelliEMS contains links to third party web sites. These links are
                         provided solely as a convenience to you and not as an endorsement by
                          IntelliEMS of the contents on such third-party web sites. IntelliEMS 
                          is not responsible for the content of linked third-party sites and does
                           not make any representations regarding the content or accuracy of 
                           materials on such third-party web sites. If you decide to access linked
                            third-party web sites, you do so at your own risk.

                        </div>
                    </div>
                    <div id="tc-amend" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4"> Amendments 
                        to this Agreement and Changes to Sites</div>
                        <div className="section-text">
                        IntelliEMS may revise these Terms and Conditions at any time by updating
                         this page. Changes will be binding on you on the date they are posted 
                         on the Sites (or as otherwise stated in the any notice of such changes).
                          Any use of a Site will be considered acceptance by you of the 
                          then-current Terms and Conditions (including any exhibits thereto) 
                          contained on such Site. If at any time you find the Terms and Conditions
                           unacceptable, you may not use the applicable Site any longer.
                            Any new or different terms supplied by you are specifically rejected
                             by IntelliEMS unless IntelliEMS agrees to them in a signed writing 
                             specifically including those new or different terms. IntelliEMS may 
                             change the Sites at any time.
                        </div>
                    </div>
                    <div id="tc-indemnity" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Indemnity</div>
                        <div className="section-text">
                        You agree to defend, indemnify, and hold harmless IntelliEMS (and its
                         subsidiaries, affiliates, officers, directors, employees and agents) 
                         from and against any third party claims, actions or demands (including,
                          without limitation, costs, damages and reasonable legal and accounting
                           fees) alleging or resulting from or in connection with your use of 
                           the Sites, any Document posted by you, your use of the Services or 
                           your breach of this Agreement. IntelliEMS shall use reasonable efforts
                            to provide you prompt notice of any such claim, suit, or proceeding 
                            and may assist you, at your expense, in defending any such claim, suit
                             or proceeding.

                        </div>
                    </div>
                    <div id="tc-user" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">User 
                        Information and Privacy Policy</div>
                        <div className="section-text">
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                When you register on any of the IntelliEMS Sites or in connection
                                 with the Services, you will be asked to create an account and 
                                 provide IntelliEMS with certain information, including but not 
                                 limited to, a valid email address ("User Information"). You 
                                 acknowledge and agree that you have no ownership rights in your 
                                 account.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                All User Information will be used in accordance with the terms of
                                 IntelliEMS's Privacy Policy. Please note, as set forth in the 
                                 Privacy Policy, that IntelliEMS may collect certain User 
                                 Information and may contact Users periodically in accordance with
                                  the terms of the Privacy Policy. In addition, IntelliEMS reserves
                                   the right to comply, in its sole discretion, with legal 
                                   requirements, requests from law enforcement agencies or requests
                                    from government entities, even to the extent that such compliance
                                     may require disclosure of certain User Information. In 
                                     addition, third parties may retain cached copies of User
                                      Information.
                            </div>
                        </div>
                    </div>
                    <div id="tc-questions" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Questions
                         and Notices</div>
                        <div className="section-text">
                        Questions concerning the use of the Sites should be directed to Feedback. 
                        Notices to IntelliEMS should be sent to the address listed on the Sites. We
                         will send notice to you at the address submitted by you or to such other 
                         address as IntelliEMS reasonably determines is an appropriate address for
                          you.
                        </div>
                    </div>
                    <div id="tc-general" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">General</div>
                        <div className="section-text">
                        IntelliEMS contact information is listed on the Sites. IntelliEMS makes no
                         claims that the Content is appropriate or may be downloaded outside of 
                         Nigeria. Access to the Content may not be legal by certain persons or in 
                         certain countries, and such persons have no right to access or use the 
                         Sites. If you access IntelliEMS from outside of Nigeria, you do so at your
                          own risk and are responsible for compliance with the laws of your 
                          jurisdiction. This Agreement and your Service Activation Agreement, 
                          if you have one, are governed by the internal substantive laws of NIgeria,
                           without respect to its conflict of laws principles. Jurisdiction for
                            any claims arising under this Agreement or your Service Activation
                             Agreement shall lie exclusively with the Law of the Federal Republic
                              of Nigeria. The sole relationship between you and IntelliEMS is that
                               of independent contractors. If any provision of this Agreement is 
                               found to be invalid by any court having competent jurisdiction, the
                                invalidity of all or part of a provision shall not affect the 
                                validity of the remaining parts and provisions of this Agreement, 
                                which shall remain in full force and effect. All provisions of this
                                 Agreement shall survive termination except those granting access 
                                 or use to the Sites, and you shall cease all your use and access
                                  thereof immediately. You may not assign or transfer your 
                                  obligations under this Agreement. No waiver of any term of this
                                   Agreement shall be deemed a further or continuing waiver of such
                                    term or any other term. Except as expressly provided by 
                                    IntelliEMS in a particular "Legal Notice," or material on 
                                    particular web pages of the Sites, this Agreement and your 
                                    Service Activation Agreement where applicable, constitute the
                                     entire agreement between you and IntelliEMS.
                        </div>
                    </div>
                    <div id="tc-rules" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Rules 
                        regarding Posting, Conduct and Security</div>
                        <div className="section-text">
                        The following rules apply to your use of the Sites and/or the Services.
                         The list of rules is for illustration only and is not a complete list 
                         of all posting, conduct and security rules.
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">Posting Rules:</span>
                                <div className="section-sub-item">
                                (a) You may not post any Document to a Site that contains: 
                                    <div className="section-sub-sub-item">
                                    (i) URLs or links to web sites other than to recruitment
                                     related pages on your company web site;
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (ii) copyrighted material (unless you own the copyright 
                                    or have the owner's permission to post the copyrighted 
                                    material);
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (iii) trade secrets (unless you own them or have the 
                                    owner's permission to post them);
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (iv) material that infringes on or misappropriates any 
                                    other intellectual property rights, or violates the privacy
                                     or publicity rights of others;
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (v) irrelevant keywords or white text keywords (including 
                                    any words embedded in the Document and hidden from the User);
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (vi) anything that is discriminatory, sexually explicit,
                                     obscene, libelous, defamatory, threatening, harassing, 
                                     abusive, or hateful; or
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (vii) anything that is embarrassing or offensive to another 
                                    person or entity.
                                    </div>
                                </div>
                                <div className="section-sub-item">
                                (b) You may not use a Document(s) to:
                                    <div className="section-sub-sub-item">
                                    (i) impersonate another person, living or dead; 
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (ii) post false, inaccurate or misleading information,
                                     opinions or notices (commercial or otherwise) or chain
                                      letters;
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (iii) post advertisements, or solicitations of business
                                     (including, but not limited to, email processors, any
                                      pyramid scheme or "club membership"); provided, 
                                      however that IntelliEMS may allow posting of certain
                                       training and business opportunities (see 14.1(e) 
                                       below). 
                                    </div>
                                </div>
                                <div className="section-sub-item">
                                (c) Your Document(s) must contain sufficient detail to convey
                                 clearly to the User the nature and requirements of the job 
                                 opportunity, or, in the case of Users seeking employment, 
                                 your qualifications as a candidate for employment. Documents
                                  that encourage the User to "email for more details" are 
                                  not permitted.
                                </div>
                                <div className="section-sub-item">
                                (d) Job postings must describe individual openings. You may
                                 not advertise multiple job openings in a single posting.
                                </div>
                                <div className="section-sub-item">
                                (e) In limited circumstances, at IntelliEMS's sole discretion,
                                 IntelliEMS allows non-traditional job postings such as 
                                 education/training, business opportunities, franchise, and
                                  multi-level marketing opportunities to be posted on the 
                                  Sites. These non-traditional job postings may require an 
                                  investment of time and/or money by the User seeking the
                                   position. IntelliEMS reserves the right, in its sole 
                                   discretion, to move, modify, or remove entirely a 
                                   non-traditional job posting if IntelliEMS deems such 
                                   job posting to conflict with the best interests of its Users
                                    or detract from the User experience.
                                </div>
                                <div className="section-sub-item">
                                (f) Resume postings must contain the accurate resume of a 
                                living individual seeking employment on a full-time, part-time,
                                 or contractual basis on his or her own behalf.
                                </div>
                                <div className="section-sub-item">
                                (g) IntelliEMS is under no obligation to monitor the Documents
                                 posted on the Sites, but it may monitor Documents at random.
                                  Documents found to violate the above Posting Rules may be 
                                  removed at IntelliEMS's sole discretion.
                                </div>
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">Conduct Rules:</span>
                                <div className="section-sub-item">
                                (a) You may not respond to postings by other Users in any 
                                manner or for any purpose other than that which is expected 
                                (i.e., to apply for the job or to initiate further discussion
                                 with the candidate). Communications soliciting the Employer's
                                  business are prohibited.
                                </div>
                                <div className="section-sub-item">
                                (b) You may not send unsolicited commercial email to Users.
                                </div>
                                <div className="section-sub-item">
                                (c) Protect your password. Your IntelliEMS account and any 
                                related Services accessed through such account are to be 
                                accessed and used solely by you. Upon your successful 
                                registration for a IntelliEMS account, you will be provided 
                                with a unique password that will permit you to access the 
                                account. You may not provide your password or otherwise permit
                                 access to your IntelliEMS account to any third party. 
                                 You are responsible for maintaining the confidentiality of 
                                 your information and password. You agree to protect and keep
                                  your password confidential, to change your password on a 
                                  regular basis, and to maintain appropriate and regularly 
                                  updated malware scanning and cleaning tools on your network
                                   and individual computer(s).You are responsible for all uses
                                    of your account, whether or not authorized by you. If others
                                     use your password to post inappropriate material on the
                                      Sites, you risk losing your access to the Sites. You agree
                                       to notify IntelliEMS immediately of any unauthorized use 
                                       of your account or password.
                                </div>
                                <div className="section-sub-item">
                                (d) Report inappropriate postings or conduct to  
                                <NavLink to="/contact-us">Feedback</NavLink>
                                </div>
                                <div className="section-sub-item">
                                (e) You may not delete or revise any material posted by any 
                                other person or entity.
                                </div>
                                <div className="section-sub-item">
                                (f) If at any time IntelliEMS comes to the understanding that
                                 you: 
                                    <div className="section-sub-sub-item">
                                    (i) misled IntelliEMS regarding your business practices 
                                    and/or services, or 
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (ii) purchased services that do not represent your 
                                    precise business, IntelliEMS reserves the right to terminate
                                     your Agreement.
                                    </div>
                                </div>
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                <span className="bold">Security Rules:</span>
                                <div className="section-sub-item">
                                (a) Users are prohibited from violating or attempting to violate
                                 the security of the Sites, including, without limitation:
                                    <div className="section-sub-sub-item">
                                    (i) accessing data not intended for such User or logging 
                                    into a server or account which the User is not authorized 
                                    to access;
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (ii) attempting to probe, scan or test the vulnerability of
                                     a system or network or to breach security or authentication 
                                     measures without proper authorization;
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (iii) attempting to interfere with service to any User, host
                                     or network, including, without limitation, via means of 
                                     submitting a virus to the Sites, overloading, "flooding", 
                                     "mail bombing" or "crashing"; 
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (iv) sending unsolicited e-mail, including promotions and/or
                                     advertising of products or services;
                                    </div>
                                    <div className="section-sub-sub-item">
                                    (v) forging any TCP/IP packet header or any part of the 
                                    header information in any e-mail or newsgroup posting.
                                    </div>
                                </div>
                                <div className="section-sub-item">
                                (b) Violation of these Security Rules may result in civil or 
                                criminal liability. IntelliEMS will investigate occurrences which
                                 may involve such violations and may involve, and cooperate with,
                                  law enforcement authorities in prosecuting Users who are 
                                  involved in such violations.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="tc-copyright" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Copyright 
                        Complaints</div>
                        <div className="section-text">
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                IntelliEMS respects the intellectual property of others. It is 
                                IntelliEMS's policy to respond to claims of copyright and other 
                                intellectual property infringement. IntelliEMS will promptly process
                                and investigate notices of alleged infringement and will take
                                appropriate actions by law. IntelliEMS may act expeditiously to
                                remove or disable access to any material claimed to be infringing 
                                or claimed to be the subject of infringing activity and may act 
                                expeditiously to remove or disable access to any reference or link
                                    to material or activity that is claimed to be infringing. 
                                    IntelliEMS will terminate access for Users who are repeat 
                                    infringers.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                Notifying IntelliEMS of Copyright Infringement: To provide 
                                IntelliEMS notice of an infringement, you must provide a written
                                 communication to the attention of  IntelliEMS (input email 
                                 address) Please also note that you may be liable for damages 
                                 (including costs and attorneys' fees) if you materially 
                                 misrepresent that an activity is infringing your copyright.
                            </div>
                            <div className="section-item">
                                <i className="bi bi-check-circle-fill text-secondary me-2" />
                                Providing IntelliEMS with Counter-Notification: If we remove or
                                 disable access to content in response to an infringement notice,
                                  we will make reasonable attempts to contact the owner or 
                                  administrator of the affected site or content. If you feel
                                   that your material does not constitute infringement, you may
                                    provide IntelliEMS with a counter notification by written 
                                    communication to the attention of IntelliEMS (input email 
                                    address). Please note that you may be liable for damages 
                                    (including costs and attorneys' fees) if you materially 
                                    misrepresent that an activity is not infringing the 
                                    copyrights of others. If you are uncertain whether an activity
                                     constitutes infringement, we recommended seeking advice of
                                      an attorney.
                            </div>
                        </div>
                    </div>
                    <div id="tc-additional" className="section-block">
                        <div className="section-title bg-primary-after after-50px mb-4">Additional
                         Terms in Connection with Use of Resume Database</div>
                        <div className="section-text">
                            If, in your Service Activation Agreement with IntelliEMS, you are 
                            purchasing Resume Database access, the following additional terms apply
                             to you:
                            <div className="section-item">
                            <i className="bi bi-check-circle-fill text-secondary me-2" />
                            IntelliEMS grants you a limited, personal, terminable, non-transferable,
                             non-exclusive right to access the IntelliEMS Resume Database via the 
                             Sites for the purpose of viewing and/or downloading a single copy of 
                             available paper and/or video resumes (collectively "Resumes") solely 
                             for your use.
                            </div>
                            <div className="section-item">
                            <i className="bi bi-check-circle-fill text-secondary me-2" />
                            The Resume Database is to be accessed and used solely by those Users 
                            whom you authorize to access the Resume Database through your account
                             (each an "Authorized RDB User"). Each Authorized RDB User will be
                              issued a unique Resume Database Seat (a "Seat") through which they 
                              can perform the actions described in 16.1. Each Seat must be 
                              purchased in advance by you and may be accessed by using unique 
                              login credentials. You are only permitted to assign one Seat per 
                              Authorized RDB User and Authorized RDB Users may not share their 
                              login credentials or Seat access amongst themselves, with any other
                               co-workers or with any other third parties.
                            </div>
                            <div className="section-item">
                            <i className="bi bi-check-circle-fill text-secondary me-2" />
                            Authorized RDB Users may download, one at a time, up to either 50 
                            Resumes per day (per Seat) or 3000 Resumes per month (per Seat) 
                            depending on the Resume Database product purchased by you in 
                            accordance with the terms of your Agreement. Please note that the 
                            term "download" shall include:
                                <div className="section-sub-item">
                                (i) viewing or clicking on a Resume page;
                                </div>
                                <div className="section-sub-item">
                                (ii) saving a Resume to a folder;
                                </div>
                                <div className="section-sub-item">
                                (iii) printing a Resume;
                                </div>
                                <div className="section-sub-item">
                                (iv) copying a Resume;
                                </div>
                                <div className="section-sub-item">
                                (v) emailing or forwarding a Resume; and/or
                                </div>
                                <div className="section-sub-item">
                                (vi) any other action that results or could result in your use
                                 of a Resume or any of the information contained therein.
                                </div>
                            </div>
                            <div className="section-item">
                            <i className="bi bi-check-circle-fill text-secondary me-2" />
                            Neither you nor your Authorized RDB Users may use the Resume
                             Database in any way which, in IntelliEMS's sole judgment, 
                             adversely affects IntelliEMS's business, business prospects, the 
                             performance or function of any Site or the Resume Database, or 
                             interferes with the ability of other subscribers to access the 
                             Resume Database. Further, use of the Resume Database by you or by
                              your Authorized RDB Users may not interfere with or violate, in 
                              any manner, the privacy election of IntelliEMS Job Seekers. You 
                              understand and agree that any violation of section 16 will give 
                              IntelliEMS the right to immediately terminate any and all access
                              to the RDB and to terminate any corresponding Agreements.
                            </div>
                            <div className="section-item">
                            <i className="bi bi-check-circle-fill text-secondary me-2" />
                            You and your Authorized RDB Users may use your subscription to the
                             Resume Database only for seeking candidates for employment and 
                             are specifically prohibited from using information contained in 
                             the Resume Database to
                                <div className="section-sub-item">
                                (i) sell or promote any products or services,
                                </div>
                                <div className="section-sub-item">
                                (ii) send emails that, in IntelliEMS's sole judgment, are 
                                excessive in frequency or contain job postings that are 
                                irrelevant to the work history of particular classes of Job
                                 Seekers or 
                                </div>
                                <div className="section-sub-item">
                                (iii) take any other action that is, in IntelliEMS's sole
                                 judgment, inconsistent with these Terms and Conditions, 
                                 misleading or incomplete, or in violation of any Law, statute,
                                  code, rule, or regulation. Company understands and agrees 
                                  that if Company uses the Resume Database in violation of the
                                   preceding restrictions, then IntelliEMS may, at its sole 
                                   discretion, either terminate Company's Agreement or transfer
                                    Company's Seats from the Resume Database to the Targeted 
                                    Resume Database (a database that only contains individuals
                                     whom IntelliEMS has identified as appropriate candidates 
                                     for sales positions where the majority of the compensation
                                      is to be derived from commissions).
                                </div>
                            </div>
                            <div className="section-item">
                            <i className="bi bi-check-circle-fill text-secondary me-2" />
                            IntelliEMS may terminate, suspend, update, alter or supplement, at
                             its sole discretion, all or any part of the Resume Database at any
                              time. By permitting access to the IntelliEMS Resume Database, 
                              IntelliEMS does not convey any interest in or to the Resume 
                              Database or any other IntelliEMS property or Services. All right,
                               title and interest in and to the Resume Database is and shall 
                               remain in IntelliEMS. 
                            </div>
                        </div>
                    </div>
                    <Footer footerBg="bg-dark footer" logoImage="/img/ims-images/intelli-log.png" />

                </section>
            </main>
        </>
    );
};

export default TermsAndConditions;