import React from 'react';
// import Background from '../../../public/img/team/team-1.jpg';

const Testimonies = () => {


  return (
      <>
        <section className="section bg-primary">
          <div className="container">
            <div className="row justify-content-between wow fadeInLeft" data-wow-duration=".4s" data-wow-delay="0.1s">
              <div className="col-lg-5 my-3">
                <label className="text-secondary mb-3">Testimonials</label>
                <h2 className="mb-4 text-white">Loved by businesses, and individuals across the globe.</h2>
                <p className="lead">Hear the testimonies from our users</p>
                <div className="pt-3">
                  <a className="btn btn-dark" >More Testimonials</a>
                </div>
              </div>
              <div className="col-lg-6 my-3 wow fadeInRight" data-wow-duration=".4s" data-wow-delay="0.1s">
                <div className="owl-carousel" data-items="2" data-nav-dots="true" data-md-items="2" data-sm-items="1"
                     data-xs-items="1" data-xx-items="1" data-space="15">
                  <div className="card overflow-hidden mb-4">
                    <div
                        className="position-relative d-flex align-items-center h-100 min-h-150px bg-cover bg-no-repeat card-top-img"
                        style={{ backgroundImage: `url(require("../../../public/img/team/team-1.jpg"))` }}
                        >

                      <div className="mask bg-primary opacity-8" />
                      <div className="position-relative w-100 text-center">
                        <h5 className="text-white m-0 font-w-600">Anslem Odigwe</h5>
                        <label className="m-0 small text-white">General Manager Residency Hotels</label>
                      </div>
                    </div>
                    <div className="mt-n4 px-4 pb-4 position-relative text-center">
                      <div className="icon-md rounded-circle text-white bg-secondary mb-3"><i
                          className="fas fa-quote-left" /></div>
                      <p className="m-0">Very easy to create staff payroll using the payroll module</p>
                    </div>
                  </div>
                  <div className="card overflow-hidden mb-4">
                    <div
                        className="position-relative d-flex align-items-center h-100 min-h-150px bg-cover bg-no-repeat card-top-img"
                        style={{ backgroundImage: `url(require("../../../public/img/team/team-1.jpg"))` }}
                        >


                      <div className="mask bg-primary opacity-8" />
                      <div className="position-relative w-100 text-center">
                        <h5 className="text-white m-0 font-w-600">Bulius Ekoja</h5>
                        <label className="m-0 small text-white">HR officer</label>
                      </div>
                    </div>
                    <div className="mt-n4 px-4 pb-4 position-relative text-center">
                      <div className="icon-md rounded-circle text-white bg-secondary mb-3"><i
                          className="fas fa-quote-left" /></div>
                      <p className="m-0">Best tool from staff and business management tool</p>
                    </div>
                  </div>
                  <div className="card overflow-hidden mb-4">
                    <div
                        className="position-relative d-flex align-items-center h-100 min-h-150px bg-cover bg-no-repeat card-top-img"
                        style={{ backgroundImage: `url(require("../../../public/img/team/team-1.jpg"))` }}
                        >

                      <div className="mask bg-primary opacity-8" />
                      <div className="position-relative w-100 text-center">
                        <h5 className="text-white m-0 font-w-600">Paul Olo</h5>
                        <label className="m-0 small text-white">Secretary MBO</label>
                      </div>
                    </div>
                    <div className="mt-n4 px-4 pb-4 position-relative text-center">
                      <div className="icon-md rounded-circle text-white bg-secondary mb-3"><i
                          className="fas fa-quote-left" /></div>
                      <p className="m-0">(attendance module)Just a button click see staff at work</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default Testimonies;
