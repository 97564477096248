import React from 'react';

const TiptopgoSubHeader = () => {
  return (
      <>
        <section id="home" className="effect-section" style={{backgroundColor: '#2A294C'}}>
          <img className="position-absolute bottom-0 start-0 pe-none w-px-100 w-px-lg-250"
               src="/img/effect/polygon-03.png" title="" alt="" />
          <img className="position-absolute top-0 end-0 pe-none w-px-100 w-px-lg-150"
               src="/img/effect/polygon-04.png" title="" alt="" />
          <div className="container position-relative">
            <div className="row min-vh-100 align-items-center py-10 wow fadeInUp" data-wow-duration="0.5s">
              <div className="col-md-6 py-8 me-auto ">
                <h1 className="display-4 text-white mb-3">
                  {/*<span className="text-info">WELCOME</span>,<br /> TIPTOPGO App*/}
                  <img src="/img/tiptopgo-images/w-logo.png" alt="" />
                </h1>
                <p className="lead text-white-85">
                  Tiptopgo is a leading online hotel booking platform that aims to revolutionize the way you book accommodations. Our mission is to provide a user-friendly and reliable platform that connects travelers with a diverse range of hotels worldwide.
                  With Tiptopgo, you can discover, compare, and book the perfect hotel for your next trip with ease.
                </p>
                <div className="pt-3 d-flex">
                  <a className="me-3" href="https://play.google.com/store/apps/details?id=com.intellims.tiptopgo" target="_blank"><img src="/img/effect/playstore.png" title="" alt="" /></a>
                  <a href="https://apps.apple.com/ng/app/tiptopgo/id6444825263" target="_blank"><img src="/img/effect/apple-store.png" title="" alt="" /></a>
                </div>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s">
                {/*<img className="lg-img-120" src="/img/ims-images/appClock2.png" title="" alt="" />*/}
                {/*<img className="lg-img-140" src="/img/ims-images/appClock-logo.png" title="" alt="" />*/}
                <img className="lg-img-140" src="/img/ims-images/tiptopLap.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default TiptopgoSubHeader;
