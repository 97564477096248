import React from "react";
import "../components/faqs/Styles.css";
import Header from "./layouts/Header";
import MetaDataDecorator from "../utilities/MetaDataDecorator";
import {IMSFAQ, APPCLOCKFAQ, TIPTOPGOFAQ, IPMSFAQ} from "../utilities/dummy";
import Footer from "./layouts/Footer";
import { useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";


const Faq = ({faqBg, faqTitle, faqItem, faqQuestion, faqAnswer, faqIcon, bgColor }) => {
    const location = useLocation();
    // console.log(location);
    let data;
    if (location.pathname === "/intelli-ems"){
        data = IMSFAQ;
    } else if (location.pathname === "/tiptopgo-app"){
        data = TIPTOPGOFAQ;
    } else if (location.pathname === "/intelli-pms"){
        data = IPMSFAQ;
    } else {
        data = APPCLOCKFAQ;
    }
    return(
        <>
        <main className={faqBg} style={{backgroundColor: bgColor}}>
            <div className={faqTitle} /*bg-light-after after-50px mb-4"*/>
                Frequently Asked Questions
            </div>
            <div className="faq-item-list" >
                {

                    data && data.map((query, index) => (
                        <div className={faqItem} key={index}>
                            <input type="checkbox" className="faq-checbox" id={"fax-checkbox" + index} />
                            <p className={faqQuestion}>{query.question}</p>
                            <label htmlFor={"fax-checkbox"+index} className={faqIcon}><i className="bi bi-caret-down-fill "/></label>
                            <p className={faqAnswer}>{query.answer}</p>
                        </div>
                    ))
                }
            </div>
        </main>
        </>
    );
};

Faq.propType = {
    faqBg: PropTypes.string,
    faqTitle: PropTypes.string,
    faqItem: PropTypes.string,
    faqQuestion: PropTypes.string,
    faqAnswer: PropTypes.string,
    faqIcon: PropTypes.string,
}

export default Faq;
