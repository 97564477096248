import React from 'react';
import { NavLink } from 'react-router-dom';

const OurProducts = () => {
  return (
      <>
        <section id="products" className="section effect-section pt-6">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp"
                 data-wow-duration="0.5s" data-wow-delay="0.1s">
              <div className="col-lg-12 col-xl-6">
                <h3 className="h1 bg-primary-after after-50px pb-3 mb-3">OUR PRODUCTS</h3>
                <div className="lead">
                  IMS Products are developed to provide modern technological solutions to the challenges of businesses and institutions.
                </div>
              </div>
            </div>
            <div className="row">
              {/*<div className="col-md-2" />*/}
              <div className="col-sm-3 col-md-3 my-3">
                <div className="card hover-rotate shadow-lg-hover hover-top wow fadeInUp" data-wow-duration="0.5s"
                     data-wow-delay="0.1s">
                  <div className="card-body text-center py-6">
                    <div className="ef-2 hr-rotate-after icon icon-xl rounded-circle bg-primary text-primary mb-5">
                      <i className=" text-white">
                        <img src="/img/ims-images/ems-icon.png" alt="" />
                      </i>
                    </div>
                    <h5 className="h6 mb-3">IntelliEMS</h5>
                    <p className="m-0">
                      The Intelligent Employment Management System (iEMS) is a digital recruitment platform that
                      simplifies the recruitment process for both employees and employers...
                    </p>
                    <NavLink to="/intelli-ems" className="btn btn-primary">
                      Learn More
                    </NavLink>
                    {/*<span className="link-white"><NavLink to="intelli-ems">Read more...</NavLink></span>*/}
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 my-3">
                <div className="card hover-rotate shadow-lg-hover hover-top wow fadeInUp" data-wow-duration="0.5s"
                     data-wow-delay="0.3s">
                  <div className="card-body text-center py-6">
                    <div className="ef-2 hr-rotate-after icon icon-xl rounded-circle mb-5"  style={{ color: '#2A294C'}}>
                      <i className="text-white">
                        <img src='/img/ims-images/tiptopgoIcon.png' alt='' />
                      </i>
                    </div>
                    <h5 className="h6 mb-3">TiptopGO</h5>
                    <p className="m-0 text-sm-center"> The ultimate hotel booking, check-in, and payment app!.This is the brainchild of the experiences and hassle of travelers in booking hotels and checking in
                    </p>
                    <NavLink to="/tiptopgo-app" className="btn" style={{backgroundColor: '#2A294C', color: '#ffff'}}>
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 my-3">
                <div className="card hover-rotate shadow-lg-hover hover-top wow fadeInUp" data-wow-duration="0.5s"
                     data-wow-delay="0.3s">
                  <div className="card-body text-center py-6">
                    <div className="ef-2 hr-rotate-after icon icon-xl rounded-circle mb-5" style={{ color: '#26326E'}}>
                      <i className="text-white">
                        <img src="/img/ims-images/ipmsLogo.png"  alt=""/>
                      </i>

                    </div>
                    <h5 className="h6 mb-3">iPMS</h5>
                    <p className="m-0 text-sm-center">
                      A solution for online bookings, payments, and check-ins. With IPMS,
                      you can streamline your hotel operations and focus on delivering exceptional guest experiences.
                    </p>
                    <NavLink to="/intelli-pms" className="btn" style={{backgroundColor: '#26326E', color: '#ffff'}}>
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
              {/*<div className="col-sm-4 col-md-3 my-3">
                <div className="card hover-rotate shadow-lg-hover hover-top wow fadeInUp" data-wow-duration="0.5s"
                     data-wow-delay="0.3s">
                  <div className="card-body text-center py-6">
                    <div className="ef-2 hr-rotate-after icon icon-xl rounded-circle bg-secondary text-secondary mb-5">
                      <i className="icon-presentation text-white" />
                    </div>
                    <h5 className="h6 mb-3">Intelli FB & FD</h5>
                    <p className="m-0"> This product is designed to help in the running of an hospitality business effectively.
                      comprising of a rich reporting modules that allow the managers and business
                      owners manage the processes of daily inflows and outflows of the business, also with users management module staffing is made..
                    </p>

                  </div>
                </div>
              </div>*/}
              <div className="col-sm-3 col-md-3 my-3">
                <div className="card hover-rotate shadow-lg-hover hover-top wow fadeInUp" data-wow-duration="0.5s"
                     data-wow-delay="0.2s">
                  <div className="card-body text-center py-6">
                    <div className="ef-2 hr-rotate-after icon icon-xl rounded-circle mb-5" style={{backgroundColor: '#50167E', color: '#50167E'}}>
                      <i className="text-white">
                        <img src="/img/ims-images/app-clock-icon.png" alt=""/>
                      </i>
                    </div>
                    <h5 className="h6 mb-3">APP ClockIn</h5>
                    <p className="m-0">
                      The “App Clockin” is a product created to provide solutions to businesses and organizations by digitally managing,
                      monitoring of staff attendance ...

                    </p>
                    <NavLink to="/intelli-clockin" className="btn" style={{backgroundColor: '#50167E', color: '#ffff'}}>
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
              {/*<div className="col-md-2" />*/}
            </div>
          </div>
        </section>
      </>
  );
};

export default OurProducts;
