export const IMSFAQ = [
    {
        question: "What is EMS?",
        answer: "EMS is an acronym for Employment Management System, it is a set of productivity web applications (for employers) and mobile applications (for employees) designed to improve life. "
    },
    {
        question: "How does it work?",
        answer: " Employees via the app (EMS app) spend less time looking for the right Employer and, Employers can easily get the best candidates and also better manage their businesses with a click of a button.        "
    },
    {
        question: "How do I sign up?",
        answer: "As an employer, access the EMS web via our website www.intellims.com/intelli-ems to find the Sign up button to fill up a bio-data form after which a user will be given access to the web upon verification of the information provided. As an employee, the EMS app can be accessed via Google Playstore and App store."
    },
    {
        question: "How to create CV",
        answer: "Locate the “My CV” option via the navigation bar, find the “Create CV” button, choose the preffered CV template, Check and uncheck the options of information you want represented in your CV tap the preview button to visualize your CV, then tap on the “Create” button to create your CV. A pop-up screen should appear, Tap on the “Done” button to access your CV in PDF format"
    },
    {
        question: "How do I generate EMS code? ",
        answer: "Locate the Manage Codes option via the navigation bar to access the manage codes screen, find the Generate code button at the button right corner of your screen and fill the required information to generate a 7-figure code."
    },
    {
        question: "How do I suppress an event?",
        answer: "Locate the notification option via the navigation bar to access the notification screen, scroll to find the event to be suppressed, and tap on view more to find the suppress button."
    },
    {
        question: "How do I create an EMS ID?",
        answer: "Locate the profile option via the navigation bar to access the profile screen, and scroll down to find “Ems ID”. Tap on the small box to copy the code as provided."
    },
    {
        question: "How do I reset my password/forgotten password?",
        answer: "Kindly contact customer care service."
    },
];

export const APPCLOCKFAQ = [
    {
        question: "What is Appclockin?",
        answer: "Appclockin is just an app that help computerise employees' attendance to work without manipulations."
    },
    {
        question: "How do I sign in?",
        answer: "Download the app from either App store or Play store, and the required details and sign in ."
    },
    {
        question: "How do I reset my password/forgotten password?",
        answer: "Contact customer care service."
    },
    {
        question: "How do I add my workplace?",
        answer: " When you sign in, a code will be sent to you via your inbox which you will add the web address of your company and the employer will clock you in."
    },
    {
        question: "Can i use the same phone number to create a new account?",
        answer: " NO, you have to use another contact number because that number is already registered."
    }
];

export const TIPTOPGOFAQ = [
    {
        question: "How do I book a hotel on Tiptopgo?",
        answer: "Booking a hotel is easy with Tiptopgo. Simply search for your desired destination, select your travel dates, browse the available hotels, and complete your booking with a few clicks"
    },
    {
        question: "What payment methods are accepted on Tiptopgo?",
        answer: "We accept various payment methods, including major credit cards and secure online payment gateways. Choose the option that suits you best during the checkout process."
    },
    {
        question: "Can I make changes to my reservation after booking?",
        answer: "Depending on the hotel's policy, you may be able to make modifications or cancellations to your reservation. Refer to the specific hotel's terms and conditions or contact our customer support for assistance."
    },
    {
        question: "How can I contact customer support if I have any issues or questions?",
        answer: "Tiptopgo provides dedicated customer support to assist you with any inquiries or concerns. You can reach our support team through the app's in-app messaging feature, email, or phone. We are committed to ensuring a smooth and satisfactory experience for our users"
    },
    {
        question: "Is there a loyalty program for frequent users of Tiptopgo?",
        answer: " Yes, Tiptopgo values the loyalty of its frequent users. We offer a loyalty program that provides exclusive benefits and perks to our regular customers. As you continue to use Tiptopgo for your hotel bookings, you can unlock higher membership tiers and enjoy even greater rewards and privileges."
    },
    {
        question: "Can I book multiple rooms for a group through Tiptopgo?",
        answer: "Absolutely! Tiptopgo allows you to book multiple rooms or accommodations for a group. Whether you're traveling with family, friends, or colleagues, you can easily select the number of rooms needed and customize your booking preferences to accommodate everyone's needs."
    }
];

export const IPMSFAQ = [
    {
        question: "What is IPMS?",
        answer: "IPMS stands for Intelligent Property Management System. It is a comprehensive software solution designed specifically for the hospitality industry to streamline hotel operations and improve overall management efficiency"
    },
    {
        question: "What are the key features of IPMS?",
        answer: `IPMS offers a wide range of features, including reservations management, guest check-in/check-out,
            housekeeping management, inventory control, accounting and billing, reporting and analytics, and integrations with other hotel systems. It provides a centralized platform to manage all aspects of hotel operations`
    },
    {
        question: "How does IPMS enhance guest experiences?",
        answer: `IPMS enables hotel staff to provide personalized and efficient services to guests. It allows for quick 
        and seamless check-ins, facilitates guest preferences and requests, manages reservations effectively, and ensures smooth communication between staff members, resulting in enhanced guest satisfaction and memorable experiences`
    },
    {
        question: "Can IPMS be customized to fit specific hotel needs?",
        answer: `Yes, IPMS is highly flexible and customizable to adapt to the unique requirements of different hotels. 
        Whether it's room configurations, pricing strategies, loyalty programs, or branding elements, 
        IPMS can be tailored to align with the specific needs and preferences of each property.`
    },
    {
        question: "Is IPMS suitable for both small and large hotels?",
        answer: `Absolutely! IPMS caters to hotels of all sizes, ranging from small boutique properties to large chains 
        and resorts. Its scalable nature allows it to handle the complexities and demands of any hotel, ensuring efficient management and seamless operations, regardless of the property's size.`
    },
    {
        question: "Is IPMS a cloud-based solution?",
        answer: `Yes, IPMS is a cloud-based software solution, which means it is hosted and accessed over the internet. 
        This allows for easy access from any device with an internet connection, real-time data synchronization, automatic updates, and improved data security.`
    },
    {
        question: "Does IPMS support multi-property management?",
        answer: `Yes, IPMS is designed to support multi-property management. It allows hotel groups and chains to centralize 
        their operations, streamline processes across multiple properties, and gain a holistic view of their business performance. It enables efficient management and reporting across different locations`
    },
    {
        question: "Can IPMS integrate with other hotel systems?",
        answer: `Yes, IPMS is built with integration capabilities, allowing it to seamlessly connect with other hotel 
        systems such as channel managers, online booking engines, point-of-sale (POS) systems, revenue management systems, 
        and more. This integration ensures smooth data flow and eliminates the need for manual data entry`
    },
   /* {
        question: "Can IPMS integrate with other hotel systems?",
        answer: `IPMS offers comprehensive support and training to its users. This includes onboarding assistance,
        training sessions, user manuals, and access to a dedicated support team. Whether it's technical issues,
        software updates, or general inquiries, IPMS provides reliable support to help users make the most of the system.`
    }*/
];
