import React from 'react';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';

const Footer = ({footerBg, logoImage}) => {
  const currentYear = new Date().getFullYear();
  return (
      <>
        {/*<footer className="bg-dark footer">*/}
        <footer className={footerBg}>
          <div className="py-7 py-md-8 py-lg-10">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 me-auto">
                  <div>
                    <img src={logoImage} title="" alt="" width="120" height="120" />
                    {/*<img src="/img/ims-images/intelli-log.png" title="" alt="" width="200" height="200" />*/}
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 my-3">
                  <h5 className="text-white footer-title-01">About</h5>
                  <ul className="list-unstyled footer-link-01 m-0">
                    <li>
                      <NavLink to="/" className="link-white">
                        IntelliIMS
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/intelli-ems" className="link-white">
                        IntelliEMS
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/intelli-clockin" className="link-white">
                        App ClockIn
                    </NavLink>

                    </li>
                    <li>
                      <a className="link-white" href="/">Online Marketing</a>
                    </li>
                    <li>
                      <a className="link-white" href="/">SEO Marketing</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-sm-6 my-3">
                  <h5 className="text-white footer-title-01">Resources</h5>
                  <ul className="list-unstyled footer-link-01 m-0">
                    <li>
                      <a className="link-white" href="/">Support Center</a>
                    </li>
                    <li>
                      <a className="link-white" href="/">Customer Support</a>
                    </li>
                    <li>
                      <a className="link-white" href="/about-us">About Us</a>
                    </li>
                    <li>
                      <a className="link-white" href="/">Copyright</a>
                    </li>
                    <li>
                      <NavLink className="link-white" to="/terms-conditions">Terms and Conditions</NavLink>
                    </li>
                    {/*<li>
                      <a className="link-white" href="/">Popular Campaign</a>
                    </li>*/}
                  </ul>
                </div>
                <div className="col-lg-3 col-sm-6 my-3">
                  <h5 className="text-white footer-title-01">Information</h5>
                  <address>
                    <p className="text-white-85 mb-2">3C, Durban Street, Wuse II, Abuja.</p>
                    <p className="text-white-85 mb-2">5 Amichi Close, Victoria Island, Lagos.</p>
                    <p className="mb-2"><a className="text-secondary border-bottom border-secondary"
                                           href="mailto:support@domain.com">Support@intellIMS.com</a></p>
                    <p className="mb-2"><a className="text-secondary border-bottom border-secondary"
                                           href="tel:+2348149069961">+2348149069961, +2347047208665</a></p>
                  </address>
                  <div className="nav pt-3">
                    <a className="icon-sm bg-secondary text-white me-2" href="https://www.facebook.com/Intelligence-Management-Systems-Intellims-101137772568240/"
                    target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a className="icon-sm bg-secondary text-white me-2" href="https://twitter.com/intell_ims?t=Czf5ILnCu1u25Y3hDKjJfQ&s=09" target="_blank">
                      <i className="fab fa-twitter" />
                    </a>
                    <a className="icon-sm bg-secondary text-white me-2" href="https://www.instagram.com/p/CcFscsTje0c/?igshid=MjU0Y2ZlMmY=" target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                    <a className="icon-sm bg-secondary text-white me-2" href="http://www.linkedin.com/in/intellims-limited-a57302237" target="_blank">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="m-0" />
            <div className="container">
              <div className="row py-3 gy-3 align-items-center">
                <div className="col-md-6">
                  <ul className="nav justify-content-center justify-content-md-start my-2 links-white small list-unstyled footer-link-01 m-0">
                    <li className="p-0 mx-3 ms-md-0 me-md-3"><NavLink to="/privacy-policy" className="link-white">Privacy Policy</NavLink>
                    </li>
                    {/*<li className="p-0 mx-3 ms-md-0 me-md-3"><NavLink to="/ims-faqs" className="link-white">Faq's</NavLink></li>*/}
                    <li className="p-0 mx-3 ms-md-0 me-md-3"><NavLink to="/terms-conditions" className="link-white">Terms and Condition</NavLink></li>
                  </ul>
                </div>
                <div className="col-md-6 text-center text-md-end">
                  <p className="text-white-85 small m-0">© {currentYear} IntelliMS is Proudly Powered by <a
                      href="www.ontractech.com" target="_blank" className="link-white">ontractech</a></p>
                </div>
              </div>
            </div>
        </footer>
      </>
  );
};

Footer.propTypes = {
  footerBg: PropTypes.string,
  logoImage: PropTypes.string,
}

export default Footer;
