import React from 'react';

const OurServices = () => {
  return (
      <>
        <section id="services" className="section bg-primary">
          <div className="container">
            <div className="row section-heading justify-content-center text-center">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 bg-light-after after-50px pb-3 mb-3 text-white wow fadeInUp" data-wow-duration=".4s"
                    data-wow-delay="0.05s">Our Services</h3>
                <div className="lead text-white-50 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.1s">
                  Making staff and human resource management seamless
                  Providing new technological solutions to ensure more efficiency in businesses
                </div>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.05s">
                <div className="media bg-white rounded-3 py-5 px-4 hover-top shadow">
                  <div className="icon icon-xl text-primary bg-gray-100 rounded-circle dots-icon">
                    <i className="icon-strategy" />
                    <span className="dots"><i className="dot dot1" /><i className="dot dot2" /><i
                        className="dot dot3" /></span>
                  </div>
                  <div className="media-body ps-4">
                    <h6>Staffing</h6>
                    <p className="m-0">
                      The main goal of staffing is to fill the various roles within the company with suitable candidates.
                      Creating of user with various departments, this includes onboarding and off boarding
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <div className="media bg-white rounded-3 py-5 px-4 hover-top shadow">
                  <div className="icon icon-xl text-primary bg-gray-100 rounded-circle dots-icon">
                    <i className="icon-alarmclock" />
                    <span className="dots"><i className="dot dot1" /><i className="dot dot2" /><i
                        className="dot dot3" /></span>
                  </div>
                  <div className="media-body ps-4">
                    <h6>Attendance monitoring/management</h6>
                    <p className="m-0">
                      Attendance management is the process of preventing employee time theft by tracking employee working
                      hours, login time, departures, breaks, and time off. Clockin/out, checkin, attendance dashboard
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.15s">
                <div className="media bg-white rounded-3 py-5 px-4 hover-top shadow">
                  <div className="icon icon-xl text-primary bg-gray-100 rounded-circle dots-icon">
                    <i className="icon-book-open" />
                    <span className="dots"><i className="dot dot1" /><i className="dot dot2" /><i
                        className="dot dot3" /></span>
                  </div>
                  <div className="media-body ps-4">
                    <h6>Payrolls Management</h6>
                    <p className="m-0">
                      Payroll management refers to the administration of employees' financial records including salaries, wages, bonuses, deductions, and net pay.
                      It entails the collection of payroll data, computation, disbursement and reporting of employees' salaries and related statutory deductions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.2s">
                <div className="media bg-white rounded-3 py-5 px-4 hover-top shadow">
                  <div className="icon icon-xl text-primary bg-gray-100 rounded-circle dots-icon">
                    <i className="icon-bargraph" />
                    <span className="dots"><i className="dot dot1" /><i className="dot dot2" /><i
                        className="dot dot3" /></span>
                  </div>
                  <div className="media-body ps-4">
                    <h6>Business Assets</h6>
                    <p className="m-0">
                      A business asset is an item of value owned by a company, they can be physical,
                      tangible goods, such as vehicles, real estate, computers, office furniture, and other fixtures, or
                      intangible items, such as intellectual property. This helps in the management and to who it was assigned
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.2s">
                <div className="media bg-white rounded-3 py-5 px-4 hover-top shadow">
                  <div className="icon icon-xl text-primary bg-gray-100 rounded-circle dots-icon">
                    <i className="icon-briefcase" />
                    <span className="dots"><i className="dot dot1" /><i className="dot dot2" /><i
                        className="dot dot3" /></span>
                  </div>
                  <div className="media-body ps-4">
                    <h6>EMS & Jobs</h6>
                    <p className="m-0">
                      Publishing of jobs, pulling of Applicants CV, this gives you access to prequalified applicants on jobs published through IntellEMS,
                      as well as managing the entire employment process.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.2s">
                <div className="media bg-white rounded-3 py-5 px-4 hover-top shadow">
                  <div className="icon icon-xl text-primary bg-gray-100 rounded-circle dots-icon">
                    <i className="icon-dribbble" />
                    <span className="dots"><i className="dot dot1" /><i className="dot dot2" /><i
                        className="dot dot3" /></span>
                  </div>
                  <div className="media-body ps-4">
                    <h6> SOS Emergency Feature</h6>
                    <p className="m-0">
                      Stay Safe & Secured by using the SOS Emergency Feature.
                      With the app on your phone, you can use the SOS feature to record your GPS location whenever you sense danger around.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default OurServices;
