import React from 'react';
import PropTypes from 'prop-types'
const MarketingVideo = ({backgroundImage, videoUrl}) => {
  return (
      <>

        <section id="media-section" className="section effect-section" >
          <div className="effect bg-gray effect-skew" />
          <div className="container " >

            <div className="row align-items-center ">
              <div className="col-lg-6 pe-xl-12 my-3 wow fadeInLeft " data-wow-duration="0.5s " data-wow-delay="0.1s ">
                <h2 className="h3 mb-4 ">WATCH OUR VIDEO <u className="text-success "><br/>Tips on How to Get Started</u></h2>
                <p className="mb-2 lead " />
                {/*<div className="pt-3 ">*/}
                {/*  <a className="btn btn-primary " href="# ">More About</a>*/}
                {/*</div>*/}
              </div>
              <div className="col-lg-6 my-3 wow fadeInRight " data-wow-duration="0.5s " data-wow-delay="0.1s ">
                <div className="shadow-lg position-relative">
                  <img className="card-img " src={backgroundImage} title=" " alt=" " />
                  <a className="after-shadow white icon-xl text-white position-absolute top-0 start-0 end-0 bottom-0 m-auto bg-white rounded-circle video-btn" href={videoUrl}><i className="bi bi-play text-primary" /> </a>
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
  );
};
MarketingVideo.protoTypes = {
  backgroundImage: PropTypes.string,
  videoUrl: PropTypes.string
}
export default MarketingVideo;
