import React from 'react';

const IPMSAppFeatures = () => {
  return (
      <>
        <section id="features" className="section bg-gray-100">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 bg-primary-after mb-3">APP FEATURES</h3>
                <div className="lead">
                  Our mobile app version offer seamless integration and an intuitive interface, ensuring a consistent and user-friendly experience across all devices.
                  Some of the basic features includes;
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-clock fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Front Desk:</h6>
                      <p className="m-0 ">
                        Streamline guest check-in and check-out processes, manage reservations, and provide personalized customer service at the front desk
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-person fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>ID/employee photo setup:</h6>
                      <p className="m-0 ">
                        iPMS App innovative ID feature allows managers to securely store staffs identification documents. No more fumbling for your passport or ID card for employees. Simply present your digital ID within the app for authentication and hassle-free verifications.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md  rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-pin-map fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Employee Attendance:</h6>
                      <p className="m-0 ">
                       with iPMS managers can view list of staff at work and clocked-in, with staff image placed side
                        by side  This is driven by iPMS clockin module which allow employees to clock-in/out via their mobile phone or dedicated desktop
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md  rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-hdd-network fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Food and Beverages:</h6>
                      <p className="m-0 ">
                        Efficiently manage restaurant operations, including order taking, table
                        reservations, menu customization, and tracking of food and beverage inventory.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-shield fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Employees:</h6>
                      <p className="m-0 ">
                        Simplify employee management with features such as attendance tracking, scheduling, \
                        payroll management, and performance evaluation.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-shield fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Kitchen:</h6>
                      <p className="m-0 ">
                        Optimize kitchen operations with inventory management, recipe tracking, order management, and kitchen workflow optimization.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-shield fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Housekeeping:</h6>
                      <p className="m-0 ">
                        Streamline housekeeping tasks with room assignment management, cleaning schedules, and real-time updates on room status.
                      </p>
                    </div>
                  </li>
                  {/*<li className="d-flex mb-3">
                    <div className="icon-md bg-secondary rounded-circle text-white">
                      <i className="bi bi-book fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Report</h6>
                      <p className="m-0 ">
                        This show history of clockin which are in range or out of range.
                      </p>
                    </div>
                  </li>*/}
                </ul>
              </div>
              <div className="col-md-6  wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.15s">
                {/*<img className="lg-img-140" src="/img/ims-images/clockin.png" title="" alt="" />*/}
                <img className="lg-img-140" src="/img/ipms-images/ipms-app-screens.png" title="" alt="" />
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-shield fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Laundry:</h6>
                      <p className="m-0 ">
                        Seamlessly manage laundry services, including tracking guest laundry requests,
                        managing inventory, and monitoring laundry operations.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-shield fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Maintenance:</h6>
                      <p className="m-0 ">
                        Ensure smooth hotel operations with maintenance management,
                        tracking equipment maintenance schedules, and managing maintenance requests.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-shield fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Reports:</h6>
                      <p className="m-0 ">
                        Access comprehensive reports on various aspects of hotel operations, including
                        financial performance, occupancy rates, guest satisfaction, and employee productivity. Gain valuable insights for data-driven decision-making.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default IPMSAppFeatures;
