import React from 'react';
import {NavLink} from 'react-router-dom';

const AboutTiptopGo = () => {
  return (
      <>
        <section id="about-us" className="section" style={{backgroundColor: '#2A294C'}}>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6  fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <h3  className="h1 bg-light-after after-50px pb-1 mb-3 text-white wow fadeInUp">ABOUT  TIPTOPGO</h3>
                <p className="lead text-white">
                  Tiptopgo is a leading online hotel booking platform that aims to revolutionize the way you book accommodations. Our mission is to provide a user-friendly and reliable platform that connects travelers with a diverse range of hotels worldwide. With Tiptopgo, you can discover, compare, and book the perfect hotel for your next trip with ease.
                </p>
                <ul className="list-type-03 mb-4 list-unstyled">
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill me-2" style={{color: '#CCD12C'}}/>
                    Seamless Hotel Booking Experience: At Tiptopgo, we strive to make the hotel booking process seamless and hassle-free.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill  me-2" style={{color: '#CCD12C'}}/>
                    Extensive Selection of Hotels: you have access to an extensive selection of hotels across various destinations.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill  me-2" style={{color: '#CCD12C'}}/>
                    Real-time Availability and Instant Confirmation: Tiptopgo provides real-time availability updates, ensuring that you have the most up-to-date information on hotel rooms.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill  me-2" style={{color: '#CCD12C'}}/>
                    Convenient Booking Options: Tiptopgo offers both a mobile app and a web version, providing you with the flexibility to book accommodations from anywhere, anytime.
                  </li>
                </ul>
                <a href='/about-tiptopgo'  className="btn text-white " style={{backgroundColor: '#CCD12C'}}>
                  Learn More
                </a>
              </div>
              <div className="col-lg-6 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <img className="lg-img-140" src="/img/tiptopgo-images/single-pic.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default AboutTiptopGo;
