import React from "react";
import "../components/privacyAndPolicy/Styles.css"
import Header from "./layouts/Header";
import MetaDataDecorator from "../utilities/MetaDataDecorator";
import Footer from "./layouts/Footer";

const PrivacyAndPolicy = () =>{
    return(
        <>
            <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link" logoImageDark="/img/ims-images/IMS-Bluey.png"  logoImageWhite="/img/ims-images/IMS-whitey.png"
                showProduct={true}/>
            <MetaDataDecorator description="Home" title="IMS | Privacy Policy" />
            <main>
                <section className="p-and-p-backdrop">
                    <div className="p-and-p-title bg-light-after after-50px mb-3 ">PRIVACY POLICY</div>
                    <div className="p-and-p-grid">
                      <span className="paragraphTitle">Introduction</span>
                        <div className="policy">
                          This privacy statement ("Privacy Statement") applies to you as a user of our products and services of ImtellIMS Limited through IntelliEMS at www.IntellIMS.com, IntelliPMS at *.intellipms.com, at Employment Management System (EMS) Mobile application, and all other websites, mobile applications and services (collectively, the "Services") provided by IntellIMS. This Privacy Statement describes our practices in handling the information that you provide to us and that we collect about you as a user of our Services. This Privacy Statement applies to all users of our Services. We may store, maintain, process and/or transfer the information you provide to us and that we collect about you in a country or countries that may have less strict data
                          protection laws than in the country where you reside. If so, we will take reasonable measures to protect your information in accordance with this Statement.
                          <div className="mt-4">
                            Your use of our Services indicates your agreement with and consent to our practices described in this Privacy Statement. If you do not consent to or agree with this Privacy Statement, please do not use any of our Services or participate in any of the features or activities relating to the Services.
                          </div>
                        </div>
                      <span className="paragraphTitle">Information we Collect</span>
                        <div className="policy">
                         <span className="subHeader-text"> Your Information: </span>
                          We collect information you provide to us in connection with our Services, such as when you create or update your account, log into your account, find a job, contact our customer service, or otherwise communicate with us via telephone, our websites, mobile applications or otherwise. This information includes your name, telephone number, email address, mailing address, photographs or other text or images you use, e.g., for your profile, the types of Services you request (collectively, "Personal Information"), as well as transaction details, billing and payment information, and other information you use or provide to us in using the Services. If you do not wish to provide any Personal Information, you may simply decline to use our Services.
                          <div className="mt-3">
                            In addition to the information you provide to us, we also collect information about you when you visit our websites, use our mobile apps or otherwise use our Services, such as how you use and interact with our Services, your selected preferences and settings, the geographic location of you, of your travel, and of your device, your Internet Protocol ("IP") address and the URL from which you access our websites and mobile apps,
                            the pages you visit on our websites, and the time and duration of your visits to, or use of our websites and mobile apps.
                          </div>
                          <div className="mt-3">
                            We may collect information about your devices, including hardware, operating systems, software, and mobile network information. Because our Services including communications between you and other users, we may collect the date and time of calls or messages, the content of messages, phone numbers, and other such call data.
                          </div>
                          <div className="mt-3">
                            <span className="subHeader-text"> Your Location:</span> In order for us to be able to provide your requested Services, you will need to grant us permission to obtain your geographic location from your device. Thereafter, you can disable this function in the settings of your device, understanding that you may not be able to avail yourself of our Services that require your location.
                          </div>
                          <div className="mt-3">
                            <span className="subHeader-text">Information from Third Parties or Other Sources:</span>
                            Information from Third Parties or Other Sources. We may also get information about you from third parties,
                            for example, if you log into your account with us via social media or any another service provider,
                            such as Facebook or a Gmail email account. If you have a corporate or business enterprise account with us,
                            we may also obtain information about your employees or about you from your employer, as the case may be.
                            Any of the IntelliEMS or IntelliPMS app or web users may provide us with information about you arising
                            out of your use of the Services.
                            You should be aware that IntellIMS cannot control the information it obtains from third parties.
                          </div>
                          <div className="mt-3">
                            <span className="subHeader-text">Cookies:</span> On our websites, mobile applications and other online Services, we use automatic data collection technologies such as beacons, pixel tags, software development kits and "cookies" in order to
                            remember user online preferences and settings, enhance your experience when using our Services, analyze online website traffic and trends,
                            be informed about a user's device and network information, and generally understand the behaviors and interests of users of our Services.
                            Cookies are small text files stored on your browser or device by websites, mobile apps and other online media that can recognize or authenticate repeat users,
                            facilitate the user's ongoing access to and use of the website and allow a website to track usage behavior and
                            compile aggregate data that will allow content improvements and targeted advertising relevant for each user.
                            Generally, cookies work by assigning a unique number to the user that has no meaning outside the assigning site.
                          </div>

                        </div>
                      <span className="paragraphTitle">How We Use Collected Information</span>
                        <div className="policy">
                          <div className="mt-3">
                            <span className="subHeader-text">To Provide and Improve the Services:</span>
                            IntelliEMS uses the information we collect to respond to your requests, send you updates, and
                            otherwise communicate with you; facilitate and otherwise process transactions; improve our Services;
                            respond to your inquiries; prevent illegal activities; comply with court orders, government requests
                            and other applicable legal requirements; enforce our Terms of Use and otherwise provide you the Services.
                            We may share your information with our vendors, consultants, and other service providers as necessary to
                            provide you Services on behalf of IntelliEMS.
                          </div>
                          <div className="mt-3">
                            <span className="subHeader-text">For Internal Purposes</span> We may use the information we collect for our internal business purposes, for example, to manage the content of our websites or to assess the amount of online traffic to our websites. IntellIMS may provide third parties with aggregate statistics about our visitors, traffic patterns and related website usage information. This data reflects website usage patterns gathered during hundreds or thousands of user visits to our websites. IntellIMS serves the right to perform, or have performed by third parties, statistical and other analyses of user behavior and characteristics in order to measure interest in and use of the various pages, or functional, graphical or informational frames or areas of the websites, and to inform advertisers of such analyses as well as the number of users that have been exposed to or clicked on their advertising banners. IntellIMS will only provide aggregated anonymous data from log files and other databases of user information to third parties to improve our content offerings, graphics, layout, ease of website use or navigation, and otherwise to provide and to improve our Services.
                          </div>
                          <div className="mt-3">
                            <span className="subHeader-text">To Communicate:</span> You will receive emails, text messages, phone calls and other communications from us to provide you with our Services, provide updates, or to respond to inquiries made through the "Contact Us" page on our websites and mobile apps. We will also facilitate communications between you and other users such as clarification of Interview invitation, CV Preview, and so forth. With respect to users who maintain a premium business account with us, we may use the contact information of such users to send emails, text messages, phone calls and other communications about the status of such premium business account. We may send you communications in which we believe you would be interested, including information about our services, promotions, products and events. You will be able to opt out of receiving such promotional email communications by clicking on links to unsubscribe within those emails.
                          </div>
                          <div className="mt-3">
                            <span className="subHeader-text">Feedback and Testimonials: </span>
                            Any testimonials or feedback that you provide to us will be utilized to improve our Services. In addition, we may use testimonials in our marketing materials, but we will not include any of your personally identifiable information in such marketing materials unless we have your written consent to do so.
                          </div>
                          <div className="mt-3">
                            <span className="subHeader-text">Platform Permissions: </span>
                             Mobile platforms such as iOS and Android define device data that mobile apps cannot access without your consent. The first time our mobile app wants to access your data, the iOS mobile platform will alert you, and you will be able to consent (or not consent) to that request to access data. An Android device will notify you that our mobile app wants to access your data before you first use the app, then your subsequent use of the app constitutes your consent.
                          </div>
                        </div>
                      <span className="paragraphTitle">How We May Share Your Information With Third Parties</span>
                        <div className="policy">
                        <span className="subHeader-text">Credit Card Transactions: </span> IntelliEMS not collect or store any credit card or other financial information when you purchase any of our Services through our websites or mobile apps. In any event, your credit card number or bank account number is not collected or stored by IntelliEMS
                            <p><i className="bi bi-check-circle-fill text-secondary me-2" />
                              We will not share your personally identifiable information with third parties except (a) as a result of a sale, acquisition, transfer of assets transaction or other fundamental corporate change involving IntelliEMS or one of its affiliates; (b) when in our sole judgment we have reason to believe that disclosing this information is reasonably necessary to prevent fraud or other violation of applicable law(s);
                              or (c) in response to subpoenas, court orders, search warrants and other government requests or as otherwise required by applicable law(s).
                            </p>
                          <span className="subHeader-text">Advertisers and Third Party Vendors:</span>
                          You should also be aware that IntelliEMS may post links to third party sites on its websites and mobile apps and sometimes permit third parties to advertise their services on its websites and mobile apps. You may choose to purchase a product or service offered by a third party by clicking on a link to such third party's website(s), etc. Please note that by clicking on an advertisement or a link to a third party website, you are leaving our websites or mobile apps and being redirected to the third party's website(s), and any access, collection, use, storage, handling, or disclosure of information you provide thereafter will be governed by the terms and conditions and the privacy policies of those other third-party websites, which may differ from this Privacy Statement.
                          IntelliEMS is not responsible for the handling of your information, or any actions, policies or breaches of privacy or security by such third parties.

                        </div>
                      <span className="paragraphTitle">Your Access to Information</span>
                      <div className="policy">
                        You may request access to your personal and account information to review it and make corrections, additions and/or deletions at any time as allowed by applicable law. Please note that we may retain certain information if it is required by law or for legitimate business purposes, such as to collect any outstanding debts on your account, follow up on any breach of our Terms of Use, evidence any fraudulent activity, and protect our rights or the rights of others.
                      </div>
                      <span className="paragraphTitle">Security Measures</span>
                      <div className="policy">
                        <p>
                          We are dedicated to ensuring that your account and information remain secure. IntelliEMS uses generally acceptable security measures to protect your personal information against unauthorized access, use, loss, misuse and alteration when such information is solely within our possession or control. We use Secure Socket layer technology (SSL) to keep others from intercepting your information while it is being transmitted across the Internet. SSL technology encrypts your information before it is transmitted to help protect it from being decoded.
                          It is the standard method of communicating and securing online transactions and is built into most commonly used browsers.
                        </p>
                        <p className="pt-3">
                          The secure mode begins when you create your account or initiate your request or order. You must create and use your password when you log in to our Services.
                          We will not ask for your password in any unsolicited communication. To enhance your online safety and keep your personal information safe, follow these recommendations:
                        </p>
                        <p><i className="bi bi-check-circle-fill text-secondary me-2" />
                          do not reveal your password to anyone and keep it protected at all times;</p>
                        <p><i className="bi bi-check-circle-fill text-secondary me-2" />
                          change your password periodically; and.
                        </p>
                        <p><i className="bi bi-check-circle-fill text-secondary me-2" />
                          create passwords that are at least 8 characters in length and a combination of lower case and capitalized letters, numbers and symbols
                        </p>
                        <p className="pt-3">
                          Please note that despite all precautionary steps, no data transmission over the Internet can be
                          guaranteed to be 100% secure. IntellIMS will use commercially reasonable measures to protect
                          your information but does not warrant that the information transmitted to us will be secure at
                          all times. Please do not provide any personal identifiable information by phone or email.
                          Even though the URL may contain IntellIMS, IntelliEMS, or IntelliPMS name, please confirm
                          your security by looking for a closed lock at the bottom of the Web page and click on the lock
                          icon to verify that the security certificate has been issued to the website. If you believe
                          there is a problem with your account, please contact our customer service at support@IntellIMS.com.
                          To report a suspicious email that uses IntellIMS, or similar trademarks of IntellIMS Limited,
                          immediately forward it to us at support@IntellIMS.com or call our corporate office
                          at +234 814-906-9961.
                        </p>
                        <p>
                          If you have questions or concerns regarding this Privacy Policy, or about the use of your personally identifiable information, you should first contact IntelliEMS via email at support@IntellIMS.com
                        </p>
                      </div>
                      <span className="paragraphTitle">Changes</span>
                      <div className="policy">
                        If our Privacy Statement or procedures change in any significant way, we will notify you by email or otherwise provide that information to you in connection with our Services.
                        Your subsequent use of our Services constitutes your agreement and consent to such changes.
                      </div>
                    </div>

                </section>
                <Footer footerBg="bg-dark footer" logoImage="/img/ims-images/intelli-log.png" />
            </main>
        </>
    );
};

export default PrivacyAndPolicy;
