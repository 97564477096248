import React from 'react';

const AboutIms = () => {
  return (
      <>
        <section className="section bg-gray-gradient-reverse">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-7 wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <h3  className="h1 bg-light-after after-50px pb-3 mb-3  wow fadeInUp">ABOUT US</h3>
                <p className="lead ">
                  We are a Nigerian based company with
                  clients all over the world. We believe that
                  Experience combined with Energy makes a
                  team complete
                </p>
                <ul className="list-type-03 mb-4 list-unstyled">
                  <li className="d-flex py-1 "><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Our management team
                    comprises of members with almost a Decade
                    of Industry experience in various fields,
                    combined with cores of software & HR
                    industry knowledge.
                  </li>
                  <li className="d-flex py-1 "><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Currently with competent direct/indirect work
                    force,combined with continuously
                    increasing network of numerous
                    partners,we have the capacity to
                    provide solutions all over Nigeria.
                  </li>
                  <li className="d-flex py-1 "><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Acquited with modern technology
                  </li>
                </ul>
              </div>
              <div className="col-lg-5 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <img src="/img/ims-images/about-us.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default AboutIms;
