import React from 'react';
import Header from './layouts/Header';
import MetaDataDecorator from '../utilities/MetaDataDecorator';
import Banner from './layouts/Banner';
import AboutIms from './About-us/AboutIms';

const AboutUs = () => {
  return (
      <>
        <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link" logoImageDark="/img/ims-images/IMS-Bluey.png"  logoImageWhite="/img/ims-images/IMS-whitey.png" showProduct={true}/>
        <MetaDataDecorator description="Home" title="IMS | About-Us" />
        <main>
          <Banner text="About Us" image='' />
          <AboutIms />
        </main>

      </>
  );
};

export default AboutUs;
