import React from 'react';
import PropTypes from 'prop-types';

const Banner = ({text, image}) => {
  return (
      <>
        <section className="bg-primary effect-section pb-8 pt-8">
          {/*<div className="effect bg-success-gradient effect-skew"  />*/}
          <div className="mask bg-primary opacity-8" />
          <div className="container position-relative">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                {/*<span className="hidden">{image}</span>*/}
                {/*<h2 className="text-success h1">Sign Up</h2>*/}
              </div>
            </div>
          </div>
        </section>
        {/*<section className="bg-primary effect-section page-heading">
          <div className="effect bg-success-gradient effect-skew"  />
          <div className="mask bg-primary opacity-8" />
          <div className="container position-relative">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <span className="hidden">{image}</span>
                <h2 className="text-success h1 mb-4">{text}</h2>
                <ol className="breadcrumb breadcrumb-light justify-content-center">
                  <li className="breadcrumb-item"><a href="/#">Home</a></li>
                  <li className="breadcrumb-item ">{text}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>*/}

      </>
  );
};

Banner.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string

}
export default Banner;

