import React from 'react';
import Header from './layouts/Header';
import MetaDataDecorator from '../utilities/MetaDataDecorator';
import ContactUsForm from './Home/ContactUsForm';
import Banner from './layouts/Banner';
import Footer from './layouts/Footer';

const ContactUs = () => {
  return (
      <>
        <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link" logoImageDark="/img/ims-images/IMS-Bluey.png"  logoImageWhite="/img/ims-images/IMS-whitey.png" showProduct={true}/>
        <MetaDataDecorator description="Home" title="IMS | Contact-Us" />
        <main>
          <Banner text="Contact Us" image='' />
          <ContactUsForm />
        </main>
        <Footer footerBg="bg-dark footer" logoImage="/img/ims-images/intelli-log.png" />
      </>
  );
};

export default ContactUs;
