import React from 'react';

const AppClockFeatures = () => {
  return (
      <>
        <section id="features" className="section bg-gray-100">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 bg-primary-after mb-3">APP FEATURES</h3>
                <div className="lead">
                  The app proves its efficacy with the features contained in it, it uses the GPS functions on the mobile
                  app to determine the location of the staff and each staff can only successfully clockin when they’re
                  within the vicinity of the workplace. Some of the basic features includes;
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-secondary rounded-circle text-white">
                      <i className="bi bi-clock fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Clock In</h6>
                      <p className="m-0 ">
                        Clock in at your place of work by tapping on the clockin button, please ensure your internet connection is on
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-secondary rounded-circle text-white">
                      <i className="bi bi-alarm fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Clock Out</h6>
                      <p className="m-0 ">
                        Clock out at your place of work by tapping on the clockin button, please ensure your internet connection is on.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-secondary rounded-circle text-white">
                      <i className="bi bi-pin-map fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Service Location</h6>
                      <p className="m-0 ">
                        This enables mobile users to identify their current physical location in the world.
                        The GPS and cellular systems along with Wi-Fi hotspots are all used to pinpoint the longitude and latitude of every user.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-secondary rounded-circle text-white">
                      <i className="bi bi-hdd-network fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>SOS</h6>
                      <p className="m-0 ">
                        An SOS is a signal which indicates to other people that you are in danger and need help quickly.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-secondary rounded-circle text-white">
                      <i className="bi bi-share fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Shift Time</h6>
                      <p className="m-0 ">
                        Keep track of shift time.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-secondary rounded-circle text-white">
                      <i className="bi bi-book fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Report</h6>
                      <p className="m-0 ">
                        This show history of clockin which are in range or out of range.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-5  wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.15s">
                {/*<img className="lg-img-140" src="/img/ims-images/clockin.png" title="" alt="" />*/}
                <img className="lg-img-140" src="/img/ims-images/app-clock-pages.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default AppClockFeatures;
