import React from 'react';
import Header from '../layouts/Header';
import MetaDataDecorator from '../../utilities/MetaDataDecorator';
import Banner from '../layouts/Banner';
// import AboutTiptopGo from './AboutTiptopgo';
import TiptopGoWebFeatures from './Tiptopgo-WebFeatures';
import TiptopgoAppFeatures from './Tiptopgo-AppFeatures';

const TiptopogoAboutDetails = () => {
  return (
      <>
        <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link" logoImageDark="/img/tiptopgo-images/logodark.png"
                logoImageWhite="/img/tiptopgo-images/logowhite.png"
                showProduct={false} />
          <MetaDataDecorator description="Home" title="TiptopGo | TiptopGo-Feature" />
          <Banner text="TiptopGo-Feature" image='' />
          {/*<AboutTiptopGo />*/}
          <TiptopGoWebFeatures />
          <TiptopgoAppFeatures />
        </>
        );
};

export default TiptopogoAboutDetails;
