import React from 'react';
import './home.css'
import HomeSlider from './HomeSlider';
import {NavLink} from 'react-router-dom';

const SubHeader = () => {
  return (
      <>
        <section id="home" className="effect-section bg-gray-100">
          <div className="effect bg-primary effect-skew" />
          <div className="particles-box" id="particles-box" />
          <div className="container position-relative">
            <div className="row min-vh-100 align-items-center py-10">
              <div className="col-12 col-lg-5 col-xl-4 py-8 wow fadeInUp" data-wow-duration="0.4s">


                  <img className="" src="/img/ims-images/Manageeee2.png" title="" alt="" />

                <p className="lead text-white-50">
                  We proffer new effective solutions to staff management, help manage and run businesses to improve efficiency.
                </p>

                  <NavLink className="btn btn-success bg-success-gradient-dark mb-0 btn-xl text-nowrap" to="/create-account">
                    <i className="fab fa-ubuntu mr-3" /> Create Account
                  </NavLink>
                 {/* <a className="btn btn-light mb-0 ms-2 text-nowrap" href="#">
                    <i className="fab fa-apple me-2" /> App Store
                  </a>*/}

                {/*<span className=" mb-0 ms-2 text-nowrap">
                    <img  src="/img/ims-images/googlestore1.png" alt="" width="150"/>
                  </span>
                <span className=" mb-0 ms-2 text-nowrap">
                    <img src="/img/ims-images/applestore1.jpg" alt="" width="150"/>
                  </span>*/}
                {/*<button  className="btn btn-lg btn-secondary mb-0 mt-2 ms-2 text-nowrap">Creat an Account </button>*/}

              </div>
              <div className="col-lg-7 col-xl-8 text-center wow fadeInUp" data-wow-duration=".4s">
                {/*<img className="lg-img-140" src="/img/ims-images/layback.png" title="" alt="" />*/}
                <HomeSlider />
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default SubHeader;
