import React from 'react';
import {NavLink} from 'react-router-dom';

const AboutAppClock = () => {
  return (
      <>
        <section id="about-us" className="pt-3 pb-3 bg-gray">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 bg-primary-after mb-3">WEB FEATURES</h3>
                <div className="lead">
                  The Web with its amazing features enables employers to determine accurately the hours worked by employees.
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.15s">
                <img className="lg-img-120" src="/img/ims-images/yuyuou.png" title="" alt="" />
                <ul>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-book fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Information Report</h6>
                      <p className="m-0 ">
                        This shows a user’s monthly review. It includes the total working days/ hours a user is supposed to work for in a month, the actual days and hours the user worked, and the number of times the user also clocked in/ out within and out of range
                      </p>
                    </div>
                  </li>

                </ul>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-laptop fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Dashboard:</h6>
                      <p className="m-0 ">
                        This shows the graphical representation of staff present and absents on each working day
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-card-checklist fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Login Report</h6>
                      <p className="m-0 ">
                        This shows the number of times a staff logs into the app
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-bell fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Clockin Reports</h6>
                      <p className="m-0 ">
                        This shows the daily clock-in/ clock-out of staff i.e the last time/date a user clocked in/ out and the shift schedule
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-person fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Checkin Reports</h6>
                      <p className="m-0 ">
                        This shows the last check-in time and the check-in intervals a staff is to check-in in a day. E.g the securities check-in at intervals of 2hrs during their shift
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-card-heading fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>SOS Report</h6>
                      <p className="m-0 ">
                        Can be activated/deactivated. When activated can be used for emergency reasons to help locate/ find a user
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-folder fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Admin Management</h6>
                      <p className="m-0 ">
                        Admin Management entails the details of the admin using the admin interface. We can have multiple admins. It has the admin name, email, phone number, and the last login day/time the admin logged in last
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-newspaper fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Company Management</h6>
                      <p className="m-0 ">
                        As the name implies, this carries company details such as the company ID/ Name, Latitude/ Longitude, range in meters, it also shows if the company’s status is active or inactive
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-collection fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Employee Management</h6>
                      <p className="m-0 ">
                        This entails the name of users, the company they work for, status if an employee is still active or inactive in a particular company and mainly to add new employees or edit employee details
                      </p>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </section>
        <section id="about-us" className="section bg-primary">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-7  fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <h3  className="h1 bg-light-after after-50px pb-1 mb-3 text-white wow fadeInUp">ABOUT APP CLOCKIN</h3>
                <p className="lead text-white">
                  The App Clockin is a product created to provide solutions to businesses and organizations by digitally managing,
                  monitoring the attendance register of its staff hereby solving the problem of time theft.
                </p>
                <ul className="list-type-03 mb-4 list-unstyled">
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    The app proves its efficacy with the features contained in it, it uses the GPS functions on the mobile
                    app to determine the location of the staff and each staff can only successfully clockin when they’re within the vicinity of the workplace
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    The GPS and cellular systems along with Wi-Fi hotspots are all used to pinpoint the longitude and latitude of every user.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    This app is equipped with modern technology
                  </li>
                </ul>
                <a  className="btn btn-secondary">
                  Learn More
                </a>
              </div>
              <div className="col-lg-5 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <img className="lg-img-140" src="/img/ims-images/clockin.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default AboutAppClock;
