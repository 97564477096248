import React from 'react';

const IPMSPricing = () => {
  return (
      <>
        <section id="pricing" className="section bg-gray-100">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp" data-wow-duration=".4s"
                 data-wow-delay="0.1s">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 bg-primary-after after-50px pb-3 mb-3">Pricing Plans</h3>
                <div className="lead">iPMS provides you with four(4) awesome solutions at an affordable rate.
                </div>
              </div>
            </div>
            <div className="row align-items-center gy-4">
              <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.05s">
                <div className="shadow shadow-lg-hover px-4 py-6 bg-white hover-top border rounded-3">
                  <div className="pb-3 mb-3 border-bottom">
                    <h5 className="m-0 h1">&#8358;0</h5><span>/month</span>
                  </div>
                  <h6 className="mb-2">iPMS Lite</h6>
                  <ul className="list-unstyled mb-0">
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Booking and payment
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Front desk operations
                    </li>
                    <li className="py-2"><i className="bi-x-circle-fill text-danger me-1"/>Housekeeping management
                    </li>
                    <li className="py-2"><i className="bi-x-circle-fill text-danger me-1"/>Inventory management
                    </li>
                    <li className="py-2"><i className="bi-x-circle-fill text-danger me-1"/>Revenue tracking
                    </li>
                    <li className="py-2"><i className="bi-x-circle-fill text-danger me-1"/>Customization options
                    </li>
                    <li className="py-2"><i className="bi-x-circle-fill text-danger text-secondary me-1"/>Remote management
                    </li>
                    <li className="py-2"><i className="bi-x-circle-fill text-danger text-secondary me-1"/>
                      On-site management
                    </li>
                  </ul>
                  <div className="pt-4 mt-3 border-top">
                    <a href="https://www.tiptopgo.com/register-hotel" target="_blank" className="btn btn-primary">Register Now</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.1s">
                <div className="shadow shadow-lg-hover px-4 py-6 bg-white hover-top border rounded-3">
                  <div className="pb-3 mb-3 border-bottom">
                    <h6 className="m-0 h1">&#8358;50,000</h6><span>/month</span>
                  </div>
                  <h6 className="mb-2">iPMS Complete</h6>
                  <ul className="list-unstyled mb-0">
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Booking and payment
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Front desk operations
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Housekeeping management
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Inventory management
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Revenue tracking
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Customization options
                    </li>
                    <li className="py-2"><i className="bi-x-circle-fill text-danger me-1"/>Remote management
                    </li>
                    <li className="py-2"><i className="bi-x-circle-fill text-danger me-1"/>
                      On-site management
                    </li>
                  </ul>
                  <div className="pt-4 mt-3 border-top">
                    <a href="https://www.tiptopgo.com/register-hotel" target="_blank" className="btn btn-primary">Register Now</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.15s">
                <div className="shadow shadow-lg-hover px-4 py-6 bg-white hover-top border rounded-3">
                  <div className="pb-3 mb-3 border-bottom">
                    <h5 className="m-0 h1">&#8358;150,000</h5>
                    <span>/month</span>
                  </div>
                  <h6 className="mb-2">iPMS Gold</h6>
                  <ul className="list-unstyled mb-0">
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Booking and payment
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Front desk operations
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Housekeeping management
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Inventory management
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Revenue tracking
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Customization options
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Remote management
                    </li>
                    <li className="py-2"><i className="bi-x-circle-fill text-danger me-1"/>
                      On-site management
                    </li>
                  </ul>
                  <div className="pt-4 mt-3 border-top">
                    <a href="https://www.tiptopgo.com/register-hotel" target="_blank" className="btn btn-secondary">Register Now</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.15s">
                <div className="shadow shadow-lg-hover px-4 py-6 bg-white hover-top border rounded-3">
                  <div className="pb-3 mb-3 border-bottom">
                    <h5 className="m-0 h1">&#8358;200,000</h5>
                    <span>/month</span>
                  </div>
                  <h6 className="mb-2">iPMS Advance</h6>
                  <ul className="list-unstyled mb-0">
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Booking and payment
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Front desk operations
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Housekeeping management
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Inventory management
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Revenue tracking
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>Customization options
                    </li>
                    <li className="py-2"><i className="bi-x-circle-fill text-danger me-1"/>Remote management
                    </li>
                    <li className="py-2"><i className="bi-check-circle-fill text-secondary me-1"/>
                      On-site management
                    </li>
                  </ul>
                  <div className="pt-4 mt-3 border-top">
                    <a href="https://www.tiptopgo.com/register-hotel" target="_blank" className="btn btn-secondary">Register Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
  );
};

export default IPMSPricing;
