import React from 'react';
import {NavLink} from 'react-router-dom';

const TiptopGoWebFeatures = () => {
  return (
      <>
        <section id="about-us" className="pt-5 pb-3 bg-gray">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 bg-primary-after mb-3">WEB FEATURES</h3>
                <div className="lead">
                  With Tiptopgo Web, you have access to all the essential features you need to make your hotel booking process effortless. Here are some of the key features:
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.15s">
                <img className="lg-img-120" src="/img/tiptopgo-images/web-screen.png" title="" alt="" />
                <ul>
                  <li className="d-flex mb-3">
                    <div className="icon-md  rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-book fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Secure and Reliable:</h6>
                      <p className="m-0 ">
                        We prioritize the security of your personal information and payment details. Tiptopgo utilizes industry-standard security protocols to protect your data and offers secure payment options, giving you peace of mind throughout the booking process.
                      </p>
                    </div>
                  </li>

                </ul>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-laptop fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Easy Hotel Search:</h6>
                      <p className="m-0 ">
                        Our advanced search functionality allows you to find hotels based on location, price, amenities, and more. With just a few clicks, you can narrow down your options and discover the perfect hotel that meets your specific preferences.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-card-checklist fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Real-time Availability:</h6>
                      <p className="m-0 ">
                        Say goodbye to outdated information. Tiptopgo provides real-time availability updates, ensuring that you have the most up-to-date information on hotel rooms. No more disappointments due to unavailability - book with confidence.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-bell fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Great Deals and Discounts:</h6>
                      <p className="m-0 ">
                        Save money on your hotel bookings with Tiptopgo. We partner with hotels to bring you exclusive deals and discounts, ensuring that you get the best value for your money. Discover amazing offers and enjoy more savings on your next trip
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-person fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>User-friendly Interface:</h6>
                      <p className="m-0 ">
                        We understand the importance of simplicity and ease-of-use. Tiptopgo features a clean and intuitive interface, making it easy for users of all levels of technical expertise to navigate and book hotels effortlessly
                      </p>
                    </div>
                  </li>
                  {/*<li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-card-heading fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>SOS Report</h6>
                      <p className="m-0 ">
                        Can be activated/deactivated. When activated can be used for emergency reasons to help locate/ find a user
                      </p>
                    </div>
                  </li>*/}
                  {/*<li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-folder fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Admin Management</h6>
                      <p className="m-0 ">
                        Admin Management entails the details of the admin using the admin interface. We can have multiple admins. It has the admin name, email, phone number, and the last login day/time the admin logged in last
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-newspaper fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Company Management</h6>
                      <p className="m-0 ">
                        As the name implies, this carries company details such as the company ID/ Name, Latitude/ Longitude, range in meters, it also shows if the company’s status is active or inactive
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-collection fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Employee Management</h6>
                      <p className="m-0 ">
                        This entails the name of users, the company they work for, status if an employee is still active or inactive in a particular company and mainly to add new employees or edit employee details
                      </p>
                    </div>
                  </li>*/}
                </ul>
              </div>

            </div>
          </div>
        </section>
        {/*<section id="about-us" className="section bg-primary">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6  fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <h3  className="h1 bg-light-after after-50px pb-1 mb-3 text-white wow fadeInUp">ABOUT APP CLOCKIN</h3>
                <p className="lead text-white">
                  Tiptopgo is a leading online hotel booking platform that aims to revolutionize the way you book accommodations. Our mission is to provide a user-friendly and reliable platform that connects travelers with a diverse range of hotels worldwide. With Tiptopgo, you can discover, compare, and book the perfect hotel for your next trip with ease.
                </p>
                <ul className="list-type-03 mb-4 list-unstyled">
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Seamless Hotel Booking Experience: At Tiptopgo, we strive to make the hotel booking process seamless and hassle-free.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Extensive Selection of Hotels: you have access to an extensive selection of hotels across various destinations.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Real-time Availability and Instant Confirmation: Tiptopgo provides real-time availability updates, ensuring that you have the most up-to-date information on hotel rooms.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Convenient Booking Options: Tiptopgo offers both a mobile app and a web version, providing you with the flexibility to book accommodations from anywhere, anytime.
                  </li>
                </ul>
                <a  className="btn btn-secondary">
                  Learn More
                </a>
              </div>
              <div className="col-lg-6 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <img className="lg-img-140" src="/img/tiptopgo-images/single-pic.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>*/}
      </>
  );
};

export default TiptopGoWebFeatures;
