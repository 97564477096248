import React from 'react';
import Header from '../layouts/Header';
import MetaDataDecorator from '../../utilities/MetaDataDecorator';
import Banner from '../layouts/Banner';

const EmsFeaturesDetails = () => {
  return (
      <>
        <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link " logoImage="/img/ims-images/ems-logo.png"
                logoImageDark="/img/ims-images/ems-logo.png"  logoImageWhite="/img/ims-images/ems-logo.png"
                showProduct={false}/>
        <MetaDataDecorator description="Home" title="EMS | EMS-Feature" />
        <Banner text="EMS-Feature" image='' /><section id="features" className="section bg-gray-100">
        <div className="container">
          <div className="row section-heading justify-content-center text-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
            <div className="col-lg-8 col-xl-6">
              <h3 className="h1 bg-primary-after mb-3">WEB FEATURES</h3>
              <div className="lead">
                The Web with its amazing features enables employers to effectively
                monitor the onboarding and offboarding of employees, Management of businesses to improve efficacy.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
              <ul className="list-unstyled mb-0">
                <li className="d-flex mb-3">
                  <div className="icon-md bg-primary rounded-circle text-white">
                    <i className="bi bi-laptop fs-5"/>
                  </div>
                  <div className="col ps-3">
                    <h6>Dashboard:</h6>
                    <p className="m-0 ">
                      This is a visual display of all of your data, its primary intention is to provide information at-a-glance, such as KPIs. A dashboard usually sits on its own page and receives information from a linked database.
                    </p>
                  </div>
                </li>
                <li className="d-flex mb-3">
                  <div className="icon-md bg-primary rounded-circle text-white">
                    <i className="bi bi-card-checklist fs-5"/>
                  </div>
                  <div className="col ps-3">
                    <h6>Message Board</h6>
                    <p className="m-0 ">
                      This is a feature used to send messages to all the employees on the system, it’s an effective means of communication that helps to convey real-time instructions or assignments for proper execution.
                    </p>
                  </div>
                </li>
                <li className="d-flex mb-3">
                  <div className="icon-md bg-primary rounded-circle text-white">
                    <i className="bi bi-bell fs-5"/>
                  </div>
                  <div className="col ps-3">
                    <h6>Notification</h6>
                    <p className="m-0 ">
                      This feature gives you instant notifications to keep you updated on new development.
                    </p>
                  </div>
                </li>
                <li className="d-flex mb-3">
                  <div className="icon-md bg-primary rounded-circle text-white">
                    <i className="bi bi-person fs-5"/>
                  </div>
                  <div className="col ps-3">
                    <h6>Employees</h6>
                    <p className="m-0 ">
                      This is a feature used in Onboarding, offboarding and effective management of employees.
                    </p>
                  </div>
                </li>
                <li className="d-flex mb-3">
                  <div className="icon-md bg-primary rounded-circle text-white">
                    <i className="bi bi-card-heading fs-5"/>
                  </div>
                  <div className="col ps-3">
                    <h6>My Account</h6>
                    <p className="m-0 ">
                       This feature is used for subscription renewal to enable the employer to continue his experience.
                    </p>
                  </div>
                </li>


              </ul>
            </div>
            <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.15s">
              <img className="lg-img-140" src="/img/ims-images/3-ems-Screenzy.png" title="" alt="" />
              <ul>
                <li className="d-flex mb-3">
                  <div className="icon-md bg-primary rounded-circle text-white">
                    <i className="bi bi-collection fs-5"/>
                  </div>
                  <div className="col ps-3">
                    <h6>EMS & Jobs</h6>
                    <p className="m-0 ">
                      This is a feature that helps employers create jobs for hiring purposes.
                    </p>
                  </div>
                </li>
                <li className="d-flex mb-3">
                  <div className="icon-md bg-primary rounded-circle text-white">
                    <i className="bi bi-collection fs-5"/>
                  </div>
                  <div className="col ps-3">
                    <h6>Business</h6>
                    <p className="m-0 ">
                   This feature enables employers to create as many businesses as they can.
                    </p>
                  </div>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </section>

        <section id="features" className="section bg-gray-100">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 bg-primary-after mb-3">APP FEATURES</h3>
                <div className="lead">
                  The app helps to create a safe space for both parties as it makes available job opportunities to job
                  seekers after they must have gone through vetting process. The app contains in it some features that ensures both parties are protected.
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-card-checklist fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Isummary</h6>
                      <p className="m-0 ">
                        Highlights of important information such as; work background, Identification, Education, Addresses, Guarantors.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-person fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Profile</h6>
                      <p className="m-0 ">
                        Contains Basic information such as; Name (Surname and First name), Gender,
                        Marital status, Date of birth, Email, Phone number, state of origin, Town of residence, Qualification, Ems ID.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-window fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Payment Records</h6>
                      <p className="m-0 ">
                        Keep track of your subscription history and payment status.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-bell fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Notifications</h6>
                      <p className="m-0 ">
                        Get instant notifications to keep you updated of new development even when not logged on the app
                      </p>
                    </div>
                  </li>


                </ul>
              </div>
              <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.15s">
                <img className="lg-img-120" src="/img/ims-images/222.png" title="" alt="" />
                <ul>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-collection fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Manage Codes</h6>
                      <p className="m-0 ">
                        Keep track of all profile requests, invites and job offers,
                        this also helps to keep track of who you want to give access to your information.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.20s">
                <ul className="list-unstyled mb-0 pt-3">
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-briefcase fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Find Jobs</h6>
                      <p className="m-0 ">
                        Keep track of jobs published by employers, you can also browse for jobs that suits your experience
                      </p>
                    </div>
                  </li>

                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-book fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>My CV</h6>
                      <p className="m-0 ">
                        Upload you curriculum vitae (CV) here to enable your ability to find job opportunities
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-code-square fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Subscribe</h6>
                      <p className="m-0 ">
                        Renew your subscription to enable the continuity of your experience on the app
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-command fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Reporting</h6>
                      <p className="m-0 ">
                        Find all the summary of your activities ranging from jobs applied, jobs taken, and other events here
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md bg-primary rounded-circle text-white">
                      <i className="bi bi-tools fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Settings</h6>
                      <p className="m-0 ">
                        Change and adjust information such as; Occupation, Password to suit your experience
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default EmsFeaturesDetails;
