import React from 'react';
import Header from './layouts/Header';
import MetaDataDecorator from '../utilities/MetaDataDecorator';
import MarketingVideo from './EMS/MarketingVideo';
import Faq from './Faqs';
import Footer from './layouts/Footer';
import TiptopgoSubHeader from './Tiptipgo-App/Tiptopgo-SubHeader';
import TiptopgoAppFeatures from './Tiptipgo-App/Tiptopgo-AppFeatures';
import AboutTiptopGo from './Tiptipgo-App/AboutTiptopgo';
import WhyBook from './Tiptipgo-App/WhyBook';
import TiptopGoWebFeatures from './Tiptipgo-App/Tiptopgo-WebFeatures';

const TiptopgoApp = () => {
  return (
      <>
        <div className="wrapper">
          <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link" logoImageDark="/img/tiptopgo-images/logodark.png"
                  logoImageWhite="/img/tiptopgo-images/logowhite.png"
                  showProduct={false} />
          <MetaDataDecorator description="Home" title="TiptopGo | Home" />
          <main>
            <TiptopgoSubHeader />
            <WhyBook />
            <AboutTiptopGo />
            <TiptopGoWebFeatures />
            <TiptopgoAppFeatures />
            <MarketingVideo backgroundImage="/img/tiptopgo-images/media-back.jpg"  videoUrl="https://www.youtube.com/embed/fLedqVGtYG0?si=6VttPBw4Ebop5sTn" />
            <Faq faqBg="faq-main-b" faqTitle="faq-title-b bg-light-after after-50px mb-4" faqItem="faq-item-b"
                 faqQuestion="faq-question-b" faqAnswer="faq-answer-b" faqIcon="faq-answer-icon-b" bgColor='#2A294C'/>
          </main>
        </div>
        <Footer footerBg="bg-dark footer" logoImage="/img/tiptopgo-images/logowhite.png" />
      </>
  )
};

export default TiptopgoApp;
