import React from 'react';
import Header from './layouts/Header';
import MetaDataDecorator from '../utilities/MetaDataDecorator';
import Banner from './layouts/Banner';
import RegistrationForm from './Registrations/RegistrationForm';
import Footer from './layouts/Footer';

const SignUp = () => {
  return (
      <>
        <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link" logoImageDark="/img/ims-images/IMS-Bluey.png"  logoImageWhite="/img/ims-images/IMS-whitey.png" showProduct={true}/>
        <MetaDataDecorator description="Home" title="IMS | Sign-Up"  />
        <main>
          <Banner text="" image="" />
          {/*<Banner text="Sign up" image='' />*/}
          <RegistrationForm />
        </main>
        <Footer footerBg="bg-dark footer" logoImage="/img/ims-images/intelli-log.png" />
      </>
  );
};

export default SignUp;
