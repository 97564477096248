import React from 'react';

const IPMSSubHeader = () => {
  return (
      <>
        <section id="home" className="effect-section bg-dark">
          <img className="position-absolute bottom-0 start-0 pe-none w-px-100 w-px-lg-250"
               src="/img/effect/polygon-03.png" title="" alt="" />
          <img className="position-absolute top-0 end-0 pe-none w-px-100 w-px-lg-150"
               src="/img/effect/polygon-04.png" title="" alt="" />
          <div className="container position-relative">
            <div className="row min-vh-100 align-items-center py-10 wow fadeInUp" data-wow-duration="0.5s">
              <div className="col-md-6 py-8 me-auto ">
                <h1 className="display-4 text-white mb-3">
                  {/*<span className="text-info">WELCOME</span>,<br /> TIPTOPGO App*/}
                  <img src="/img/ipms-images/ipms-welcome.png" alt="" />
                </h1>
                <p className="lead text-white-85">
                  As a hotel owner, you know that managing your operations can be a challenging and time-consuming task.
                  That's why we've developed IPMS - a cutting-edge, all-in-one solution for online bookings, payments, and check-ins. With IPMS, you can streamline your hotel operations and focus on delivering exceptional guest experiences
                </p>
                <div className="pt-3 d-flex">
                  {/*<a className="me-3" href="https://play.google.com/store/apps/details?id=com.intellims.tiptopgo" target="_blank"><img src="/img/effect/playstore.png" title="" alt="" /></a>
                  <a href="https://apps.apple.com/ng/app/tiptopgo/id6444825263" target="_blank"><img src="/img/effect/apple-store.png" title="" alt="" /></a>*/}
                </div>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s">
                {/*<img className="lg-img-120" src="/img/ims-images/appClock2.png" title="" alt="" />*/}
                {/*<img className="lg-img-140" src="/img/ims-images/appClock-logo.png" title="" alt="" />*/}
                <img className="lg-img-140" src="/img/ipms-images/ipms-lap.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default IPMSSubHeader;
