import React from 'react';
import {NavLink} from 'react-router-dom';
import './aboutSyle.css'
const AboutEms = () => {
  return (
      <>
        <section className="section bg-gray-100">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp"
                 data-wow-duration="0.5s" data-wow-delay="0.1s">
              <div className="col-md-12">
                <h3 className="h1 bg-primary-after after-50px">About IntellEMS</h3>
                <div className="lead"> It is a set of productivity web applications (for employers) and mobile applications(for employees) designed to improve life
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between flex-row-reverse">
              <div className="col-md-6 my-1 wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <label className="bg-secondary px-3 py-1 text-white rounded-3 mb-3">Best Tools For Employers</label>
                <p className="lead l">
                  Employers <span className="link-dark"><NavLink to="/create-account">sign-up</NavLink></span> for your IntelliEMS web account from any web browser to enjoy our basic services or upgrade to our premium
                  features that gives you total control of your hiring and managing of staffs. Features include best-of-breed services like
                </p>
                <ul className="list-type-03 mb-4 list-unstyled">
                  <li className="d-flex py-1"><i className="bi bi-check-circle-fill text-secondary me-2" />
                  Employment Reference Check to get details of a potential employee work history. No more embellishing of
                    truth by an employee with intelligence information in the database from their past employers.
                  </li>
                  <li className="d-flex py-1"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Applicant CV Pool gives you access to prequalified applicants on jobs you publish through IntellEMS.
                    Plus your job advertisement is pushed to many of our online jobs partners.
                  </li>
                  <li className="d-flex py-1"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    A-Z Hiring Workflow gives you the ultimate control in the entire hiring process, starting with prequalifying applicants, scheduling and managing job interview process,
                    making job offers to selected candidate, all the to migrating them into your payroll database
                  </li>
                  <li className="d-flex py-1"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Integrated AppClockIn allows companies to monitor and manage actual hours that employees worked by incorporating
                    virtual geo-fencing into their payroll to only pay staffs for the hours they showed up to work.
                  </li>
                </ul>
                <a href="/intellems-features" className="btn btn-primary">Learn More</a>
                {/*<div className="pt-3 d-flex">

                  <NavLink className="btn btn-success bg-success-gradient-dark mb-0 btn-xl text-nowrap" to="/create-account">
                    <i className="fab fa-ubuntu mr-3" /> Create Account
                  </NavLink>
                </div>*/}

                {/*<div className="createBtn">

                  <NavLink className="btn btn-success bg-success-gradient-dark mb-0 btn-xl text-nowrap" to="/create-account">
                    <i className="fab fa-ubuntu mr-3" /> Create Account
                  </NavLink>
                </div>*/}
              </div>
              <div className="col-md-6 my-1 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <img className="sm-img-120" src="/img/ims-images/EMS-new-tabs.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section id="about-us" className="section bg-primary">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-7  fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <label className="bg-secondary px-3 py-1 text-white rounded-3 mb-3">Let's Get You A Job</label>
                <p className="lead text-white">
                  Employees/Job Seekers download IntellEMS App from the Android Store or Apple Store to get started with our
                  basic and advanced features. It is free to use. Some of the free features include;
                </p>
                <ul className="list-type-03 mb-4 list-unstyled">
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Our Online Jobs gives you instant access to thousands of employment vacancies with top notched companies.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Resume/CV function will format your CV into international standard and present our candidacy to the best possible.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    This app is equipped with modern technology
                  </li>
                </ul>
                <a  className="btn btn-secondary" href="/intellems-features">
                  Learn More
                </a>


              </div>
              <div className="col-lg-5 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <img className="lg-img-120" src="/img/ims-images/aboutEms.png" title="" alt="" />
                <div className="d-flex downloadBtn2">

                  <a className="me-3" href="/"><img src="/img/effect/playstore.png" title="" alt="" /></a>
                  <a href="/"><img  src="/img/effect/apple-store.png" title="" alt="" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
  );
};

export default AboutEms;
