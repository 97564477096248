import React from 'react';
import Header from '../layouts/Header';
import MetaDataDecorator from '../../utilities/MetaDataDecorator';
import Banner from '../layouts/Banner';
import IPMSWebFeatures from './iPMS-WebFeatures';
import IPMSAppFeatures from './iPMS-AppFeatures';

const IPmsGenFeatures = () => {
  return (
      <>
        <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link" logoImageDark="/img/ipms-images/logo-white.png"
                logoImageWhite="/img/ipms-images/logo-white.png"
                showProduct={false} />
        <MetaDataDecorator description="Home" title="iPMS | iPMS-Feature" />
        <Banner text="iPMS-Feature" image='' />
        <IPMSWebFeatures />
        <IPMSAppFeatures />
      </>
  );
};

export default IPmsGenFeatures;
