import React from 'react';
import {Routes, Route} from 'react-router-dom'
import Home from './components/Home';
// import Footer from './components/layouts/Footer';
import ContactUs from './components/ContactUs';
import SignUp from './components/SignUp';
import AboutUs from './components/AboutUs';
import EmsApp from './components/Ems-App';
import AppClock from './components/App-Clock';
import EmsFeaturesDetails from './components/EMS/EmsFeaturesDetails';
import PrivacyAndPolicy from './components/PrivacyAndPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import TiptopgoApp from './components/Tiptopgo-App';
import IPMSApp from './components/IPMS-App';
import IPmsGenFeatures from './components/iPMS/iPMS-Gen-Features';
import TiptopogoAboutDetails from './components/Tiptipgo-App/Tiptopogo-About-Details';


const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/contact-us" element={<ContactUs />} exact />
        <Route path="/create-account" element={<SignUp />} exact />
        <Route path="/about-us" element={<AboutUs />} exact />
        <Route path="/intelli-ems" element={<EmsApp />} exact />
        <Route path="/intelli-clockin" element={<AppClock />} exact />
        <Route path="/intellems-features" element={<EmsFeaturesDetails />} exact />
        <Route path="/privacy-policy" element={<PrivacyAndPolicy/>} exact/>
        <Route path='/terms-conditions' element={<TermsAndConditions/>} exact/>
        <Route path='/tiptopgo-app' element={<TiptopgoApp />} exact/>
        <Route path='/intelli-pms' element={<IPMSApp />} exact/>
        <Route path='/ipms-features' element={<IPmsGenFeatures />} exact/>
        <Route path='/about-tiptopgo' element={<TiptopogoAboutDetails />} exact/>
        {/* <Route path="/ims-faqs" element={<ImsFaq />} exact/>
        <Route path='/intelli-clockin-faqs' element={<AppClockFaq/>} exact/> */}
      </Routes>
    </>
  );
}

export default App;
