import React from 'react';
import Header from './layouts/Header';
import MetaDataDecorator from '../utilities/MetaDataDecorator';
import MarketingVideo from './EMS/MarketingVideo';
import Faq from './Faqs';
import Footer from './layouts/Footer';
import IPMSSubHeader from './iPMS/iPMS-SubHeader';
import AboutIPMS from './iPMS/AboutIiPMS';
import IPMSWebFeatures from './iPMS/iPMS-WebFeatures';
import IPMSAppFeatures from './iPMS/iPMS-AppFeatures';
import IPmsServices from './iPMS/iPMS-Services';
import IPMSPricing from './iPMS/iPMS-Pricing';

const IPMSApp = () => {
  return (
      <>
        <div className="wrapper">
          <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link" logoImageDark="/img/ipms-images/logo-white.png"
                  logoImageWhite="/img/ipms-images/logo-white.png"
                  showProduct={false} />
          <MetaDataDecorator description="Home" title="iPMS | Home" />
          <main>
            <IPMSSubHeader />
            <IPmsServices />
            <AboutIPMS />
            <IPMSWebFeatures />
            <IPMSAppFeatures />
            <MarketingVideo backgroundImage="/img/ipms-images/ipms-market.jpg"  videoUrl="https://www.youtube.com/embed/D_EGqYcNO2U?si=LNWygrQfJSRYJiy6" />
            <IPMSPricing />
            <Faq faqBg="faq-main-b" faqTitle="faq-title-b bg-light-after after-50px mb-4" faqItem="faq-item-b" faqQuestion="faq-question-b" faqAnswer="faq-answer-b" faqIcon="faq-answer-icon-b" bgColor='#515E88'/>
          </main>
        </div>
        <Footer footerBg="bg-dark footer" logoImage="/img/ipms-images/logo-white.png" />
      </>
  )
};

export default IPMSApp;
