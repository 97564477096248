import React from 'react';

const TiptopgoAppFeatures = () => {
  return (
      <>
        <section id="features" className="section bg-gray-100">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 bg-primary-after mb-3">APP FEATURES</h3>
                <div className="lead">
                  Our mobile app version offer seamless integration and an intuitive interface, ensuring a consistent and user-friendly experience across all devices.
                  Some of the basic features includes;
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-clock fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Check-in Made Easy</h6>
                      <p className="m-0 ">
                        With the Tiptopgo app, checking in at your hotel is a breeze. Skip the hassle of long queues and paperwork. Simply use the app to complete the check-in process before you arrive, and your room key will be ready for you upon arrival. Experience a seamless and efficient check-in experience with Tiptopgo.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-person fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>ID Feature for Quick Verification:</h6>
                      <p className="m-0 ">
                        Tiptopgo's innovative ID feature allows you to securely store your identification documents. No more fumbling for your passport or ID card at the check-in counter. Simply present your digital ID within the app for quick and hassle-free verification. Enjoy the convenience and peace of mind with Tiptopgo's ID feature.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md  rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-pin-map fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Extend Your Stay with Ease:</h6>
                      <p className="m-0 ">
                        Need to extend your hotel stay? Tiptopgo makes it simple. With just a few taps on the app, you can request to extend your reservation. Our user-friendly interface allows you to check the availability and duration of the extension, making it effortless to prolong your stay and enjoy more of your destination.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md  rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-hdd-network fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Hassle-free Reservation Cancellation:</h6>
                      <p className="m-0 ">
                        Plans change, and we understand that. Tiptopgo offers a convenient reservation cancellation feature through the app. If you need to cancel your booking, just access your reservation details and follow the cancellation process within the app. Enjoy the flexibility and peace of mind with our hassle-free cancellation feature.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-shield fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6> Secure and Reliable:</h6>
                      <p className="m-0 ">
                        We prioritize the security of your personal information and payment details. Tiptopgo utilizes industry-standard security protocols to protect your data and offers secure payment options, giving you peace of mind throughout the booking process.
                      </p>
                    </div>
                  </li>
                  {/*<li className="d-flex mb-3">
                    <div className="icon-md bg-secondary rounded-circle text-white">
                      <i className="bi bi-book fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Report</h6>
                      <p className="m-0 ">
                        This show history of clockin which are in range or out of range.
                      </p>
                    </div>
                  </li>*/}
                </ul>
              </div>
              <div className="col-md-5  wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.15s">
                {/*<img className="lg-img-140" src="/img/ims-images/clockin.png" title="" alt="" />*/}
                <img className="lg-img-140" src="/img/tiptopgo-images/app-screen.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default TiptopgoAppFeatures;
