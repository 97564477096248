import React from 'react';
import Header from './layouts/Header';
import MetaDataDecorator from '../utilities/MetaDataDecorator';
import AppClockinSubHeader from './APP-Clock/App-Clockin-SubHeader';
import HowAppClockWorks from './APP-Clock/HowAppClockWorks';
import AppClockFeatures from './APP-Clock/AppClockFeatures';
import AboutAppClock from './APP-Clock/AboutAppClock';
import Footer from './layouts/Footer';
import MarketingVideo from './EMS/MarketingVideo';
import Faq from './Faqs';
// https://www.youtube.com/watch?v=b5jxQaJNzjY
const AppClock = () => {
  return (
      <>
        <div className="wrapper">
          <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link" logoImageDark="/img/ims-images/app-clock-icon.png"
                  logoImageWhite="/img/ims-images/app-clock-icon.png"
                  showProduct={false} />
          <MetaDataDecorator description="Home" title="APP Clock | Home" />
          <main>
            <AppClockinSubHeader />
            <HowAppClockWorks />
            <AboutAppClock />
            <AppClockFeatures />
            <MarketingVideo backgroundImage="/img/ims-images/appy-layout.jpg"  videoUrl="" />
            <Faq faqBg="faq-main-b" faqTitle="faq-title-b bg-light-after after-50px mb-4" faqItem="faq-item-b"
                 faqQuestion="faq-question-b" faqAnswer="faq-answer-b" faqIcon="faq-answer-icon-b" bgColor='#0033CC'
            />
          </main>
        </div>
        <Footer footerBg="bg-dark footer" logoImage="/img/ims-images/app-clock-icons.png" />
      </>
  );
};

export default AppClock;
