import React from 'react';
import {NavLink} from 'react-router-dom';

const AboutIPMS = () => {
  return (
      <>
        <section id="about-us" className="section bg-dark" >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6  fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <h3  className="h1 bg-light-after after-50px pb-1 mb-3 text-white wow fadeInUp">ABOUT  iPMS</h3>
                <p className="lead text-white">
                  Welcome to the ultimate guide to Intelligence Property Management System (IPMS), the advanced hotel management software that offers four different versions to cater to varying hotel needs.
                  Our website aims to help hotel owners understand IPMS and choose the right version for their project management needs.
                </p>
                <ul className="list-type-03 mb-4 list-unstyled">
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill me-2" style={{color: '#CCD12C'}}/>
                    Simplify Your Operations: Say goodbye to manual record-keeping and hello to more free time.Our intuitive and user-friendly app simplifies the complicated, so you can focus on what really matters - your guests.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill  me-2" style={{color: '#CCD12C'}}/>
                    Elevate Your Hotel Experience: IPMS is designed to revolutionize the way you run your hotel. From online bookings to check-ins, our app makes it easy for your guests to plan their stay and experience your hotel at its best.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill  me-2" style={{color: '#CCD12C'}}/>
                    Real-time Availability and Instant Confirmation: iPMS provides real-time availability updates, ensuring that you have the most up-to-date information on hotel rooms.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill  me-2" style={{color: '#CCD12C'}}/>
                    Track Your Finances Effortlessly: With IPMS, you can track your finances with ease. Our app provides real-time updates and detailed reports, so you can stay on top of your financials and make informed business decisions. No more manual record-keeping or missed opportunities - IPMS has got you covered.
                  </li>
                </ul>
                <a href="/ipms-features" target="_blank" className="btn text-white " style={{backgroundColor: '#CCD12C'}}>
                  Learn More
                </a>
              </div>
              <div className="col-lg-6 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <img className="lg-img-140" src="/img/ipms-images/about-ipms.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default AboutIPMS;
