import React from 'react';

const WhyBook = () => {
  return (
      <>
        <section className="section">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp"
                 data-wow-duration="0.5s">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 text-dark mb-1">Why Book on Tiptopgo</h3>
                <div className="lead link-dark">Sign up with us and setup your profile to enjoy our awesome packages.
                </div>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-md-3 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <div
                    className="shadow-sm shadow-hover hover-top py-6 px-4 text-center border-bottom border-5  rounded-3"  style={{borderColor: '#CCD12C'}}>
                  <div className="icon-xl rounded-circle text-white mb-4"  style={{backgroundColor: '#CCD12C'}}>
                    <i className="icon-download"/>
                  </div>
                  <h5 className="h6 mb-3">Extensive Choice:</h5>
                  <p className="m-0">
                     You have access to a vast selection of hotels worldwide.
                    From budget-friendly options to luxury accommodations, we have something to suit every traveler's needs and preferences.
                    </p>
                </div>
              </div>
              <div className="col-md-3 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.20s">
                <div className="shadow-sm shadow-hover hover-top py-6 px-4 text-center rounded-3 " style={{backgroundColor: '#2A294C'}}>
                  <div className="icon-xl rounded-circle text-white mb-4" style={{backgroundColor: '#D0D62C'}}>
                    <i className="icon-adjustments"/>
                  </div>
                  <h5 className="text-white h6 mb-3">Convenience</h5>
                  <p className="m-0 text-white">
                    Save time and effort by using Tiptopgo as your one-stop hotel booking platform. No more browsing multiple websites or making endless phone calls - find, compare, select and book your ideal hotel in a few simple steps.
                   <p />
                  </p>
                </div>
              </div>
              <div className="col-md-3 wow fadeInUp " data-wow-duration="0.5s" data-wow-delay="0.30s">
                <div
                    className="shadow-sm shadow-hover hover-top py-6 px-4 text-center border-bottom border-5 rounded-3 bg-white" style={{borderColor: '#CCD12C'}}>
                  <div className="icon-xl rounded-circle text-white mb-4" style={{backgroundColor: '#D0D62C'}}>
                    <i className="icon-profile-male"/>
                  </div>
                  <h5 className="h6 mb-3">Reliability and Trust:</h5>
                  <p className="m-0">Tiptopgo, work with reputable hotels to ensure a high standard of quality and service. Rest assured that your accommodation will meet your expectations and provide a comfortable stay.
                  </p>
                </div>
              </div>
              <div className="col-md-3 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.20s">
                <div className="shadow-sm shadow-hover hover-top py-6 px-4 text-center rounded-3"  style={{backgroundColor: '#2A294C'}}>
                  <div className="icon-xl rounded-circle text-white mb-4"  style={{backgroundColor: '#D0D62C'}}>
                    <i className="icon-shield"/>
                  </div>
                  <h5 className="text-white h6 mb-3"> Customer Support:</h5>
                  <p className="m-0 text-white">
                     Our dedicated customer support team is available to assist you with any inquiries or concerns you may have. We are committed to providing excellent customer service and ensuring your satisfaction throughout your booking experience
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default WhyBook;
