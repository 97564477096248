import React from 'react';

const HowAppClockWorks = () => {
  return (
      <>
        <section className="section">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp"
                 data-wow-duration="0.5s">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 text-primary mb-1">How does it work?</h3>
                <div className="lead link-dark">Sign up with us and setup your profile to enjoy our awesome packages.
                </div>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <div
                    className="shadow-sm shadow-hover hover-top py-6 px-4 text-center border-bottom border-5 border-secondary rounded-3 bg-white">
                  <div className="icon-xl rounded-circle bg-secondary text-white mb-4">
                    <i className="icon-download"/>
                  </div>
                  <h5 className="h6 mb-3">Download App</h5>
                  <p className="m-0">Download the app
                    from any of the available
                    outlets,(Google Play or
                    App store)</p>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.20s">
                <div className="shadow-sm shadow-hover hover-top py-6 px-4 text-center rounded-3 bg-primary">
                  <div className="icon-xl rounded-circle bg-secondary text-white mb-4">
                    <i className="icon-adjustments"/>
                  </div>
                  <h5 className="text-white h6 mb-3">Install App</h5>
                  <p className="m-0 text-white-85">
                    installing the app on
                    your device is smooth
                    and easy
                  </p>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp " data-wow-duration="0.5s" data-wow-delay="0.30s">
                <div
                    className="shadow-sm shadow-hover hover-top py-6 px-4 text-center border-bottom border-5 border-secondary rounded-3 bg-white">
                  <div className="icon-xl rounded-circle bg-primary text-white mb-4">
                    <i className="icon-profile-male"/>
                  </div>
                  <h5 className="h6 mb-3">Sign Up</h5>
                  <p className="m-0">
                    Sign up by filling all the
                    required data and
                    bio data to get started
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default HowAppClockWorks;
