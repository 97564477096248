import React from 'react';
import {NavLink} from 'react-router-dom';
import './aboutSyle.css'

const EmsSubHeader = () => {
  return (
      <>
        <section id="home" className="bg-dark-gradient overflow-hidden pt-5">
         {/* <div className="container  bg-dark">
            mmm
          </div>*/}
          <div className="container position-relative">
            <div className="row min-vh-100 align-items-center py-5">
              <div className="col-md-7 wow fadeInUp" data-wow-duration="0.5s">
                <img className="sm-img-120" src="/img/ims-images/newiEMS.png" title="" alt="" />
                <div className="pt-3 d-flex downloadBtn">

                  <a className="me-3 downloadLink" href="/"><img src="/img/effect/playstore.png" title="" alt="" /></a>
                  <a href="/"><img  src="/img/effect/apple-store.png" title="" alt="" /></a>
                </div>
              </div>
              <div className="col-md-5 pe-xl-10 wow fadeInUp" data-wow-duration="0.5s">
                <label className="bg-secondary px-3 py-1 text-white rounded-3 mb-3">EMS offers you the best</label>
                {/*<h2 className="display-4 mb-4"> (Welcome to IntellEMS)</h2>*/}
                <p className="lead">
                  The Intelligent Employment Management System (iEMS) is a digital recruitment platform that simplifies
                  the recruitment process for both employees and employers, with the click of a button.
                </p>
                {/*<img src="/img/ims-images/welcome-job.png" title="" alt="" />*/}
                <p className="lead">
                  It is a set of productivity web applications  (for employers) and mobile applications (for employees) designed to improve life.
                  Employees spend less time looking for the right Employer. Employers can easily get the best candidates with a click of a button.
                  {/*We proffer new effective solutions of job opportunities, with the biggest companies in the world.
                  This is a product created to solve the problem of unemployment as it is a converging point for the employer and the job seeker*/}

                    <NavLink className="btn btn-success bg-secondary mb-0 btn-xl text-nowrap pt-3" to="/create-account">
                      <i className="fab fa-ubuntu" /> Create Account
                    </NavLink>
                </p>

              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default EmsSubHeader;
