import React from 'react';
import {NavLink} from 'react-router-dom';

const IPMSWebFeatures = () => {
  const location = window.location.pathname;
  return (
      <>
        <section id="about-us" className="pt-5 pb-3 bg-gray">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 bg-primary-after mb-3">WEB FEATURES</h3>
                <div className="lead">
                  With iPMS Web, you have access to all the essential features you need to make your hotel booking, manage employee process effortless. Here are some of the key features:
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.15s">
                <img className="lg-img-120" src="/img/ipms-images/web-feature.png" title="" alt="" />
                <ul>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-person fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>User-friendly Interface:</h6>
                      <p className="m-0 ">
                        We understand the importance of simplicity and ease-of-use. Tiptopgo features a clean and intuitive interface, making it easy for users of all levels of technical expertise to navigate and book hotels effortlessly
                      </p>
                    </div>
                  </li>
                  {
                    location === '/ipms-features' ? (
                          <>
                              <li className="d-flex mb-3">
                                <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                                  <i className="bi bi-folder fs-5"/>
                                </div>
                                <div className="col ps-3">
                                  <h6> Staff Attendance:</h6>
                                  <p className="m-0 ">
                                    The staff attendance utility tracks employee attendance,
                                    including clock-in and clock-out times. It helps monitor staff punctuality and manage work schedules effectively.
                                  </p>
                                </div>
                              </li>
                              <li className="d-flex mb-3">
                                <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                                  <i className="bi bi-newspaper fs-5"/>
                                </div>
                                <div className="col ps-3">
                                  <h6>Duty Logs:</h6>
                                  <p className="m-0 ">
                                    The duty logs utility records important information and activities performed
                                    during specific shifts or duty periods. It assists in maintaining an organized record of tasks and responsibilities.
                                  </p>
                                </div>
                              </li>
                              <li className="d-flex mb-3">
                                  <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                                  <i className="bi bi-collection fs-5"/>
                                  </div>
                                  <div className="col ps-3">
                                  <h6>Room Cleaning:</h6>
                                  <p className="m-0 ">
                                     The room cleaning utility assists in managing and tracking the cleaning
                                    and maintenance of hotel rooms, ensuring that they are properly serviced and prepared for guest occupancy
                                  </p>
                                  </div>
                              </li>
                            <li className="d-flex mb-3">
                              <img className="sm-img-120" src="/img/ipms-images/web-feature2.png" title="" alt="" />
                            </li>
                            <li className="d-flex mb-3">
                              <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                                <i className="bi bi-collection fs-5"/>
                              </div>
                              <div className="col ps-3">
                                <h6>Employee Control:</h6>
                                <p className="m-0 ">
                                  The employee control utility provides features to manage employee access rights, permissions, and restrictions within the system. It helps maintain data security and privacy.
                                </p>
                              </div>
                            </li>
                            <li className="d-flex mb-3">
                              <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                                <i className="bi bi-collection fs-5"/>
                              </div>
                              <div className="col ps-3">
                                <h6>Daily Revenue:</h6>
                                <p className="m-0 ">
                                  The daily revenue utility tracks and reports the financial performance of the hotel on a daily basis. It provides a summary of revenue generated from room bookings, additional services, and other sources.
                                </p>
                              </div>
                            </li>
                            <li className="d-flex mb-3">
                              <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                                <i className="bi bi-collection fs-5"/>
                              </div>
                              <div className="col ps-3">
                                <h6>Hotel Stats:</h6>
                                <p className="m-0 ">
                                  The hotel stats utility presents key performance indicators and metrics
                                  related to the hotel's operations. It includes data on occupancy rates, average room rates, revenue per available room, and other vital statistics.
                                </p>
                              </div>
                            </li>
                            <li className="d-flex mb-3">
                              <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                                <i className="bi bi-collection fs-5"/>
                              </div>
                              <div className="col ps-3">
                                <h6>Employee Stats:</h6>
                                <p className="m-0 ">
                                   The employee stats utility offers insights into employee performance
                                  and productivity. It provides data on attendance, work hours, tasks completed, and other relevant metrics to assess staff efficiency.
                                </p>
                              </div>
                            </li>
                          </>
                     ) : ''
                  }
                </ul>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <ul className="list-unstyled mb-0">
                  <li className="d-flex mb-3">
                    <div className="icon-md  rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-book fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Check-in:</h6>
                      <p className="m-0 ">
                        The check-in utility allows hotel staff to efficiently register guests upon their arrival,
                        collect necessary information, assign rooms, and provide room keys or access cards.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-laptop fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Check-out:</h6>
                      <p className="m-0 ">
                        The check-out utility streamlines the process of settling guest accounts, handling payments, and returning any deposits. It ensures a smooth and hassle-free departure experience for guests.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-card-checklist fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Create Reservation</h6>
                      <p className="m-0 ">
                        This utility enables hotel staff to create new reservations for guests,
                        specifying the desired dates, room type, and any special requests or preferences. It helps manage bookings and availability effectively.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-bell fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Reservation List: </h6>
                      <p className="m-0 ">
                        The reservation list utility provides an overview of all current and upcoming reservations,
                        allowing staff to track occupancy rates, manage room allocation, and plan for future bookings.
                      </p>
                    </div>
                  </li>
                  {/*<li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-person fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>User-friendly Interface:</h6>
                      <p className="m-0 ">
                        We understand the importance of simplicity and ease-of-use. Tiptopgo features a clean and intuitive interface, making it easy for users of all levels of technical expertise to navigate and book hotels effortlessly
                      </p>
                    </div>
                  </li>*/}
                  <li className="d-flex mb-3">
                    <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                      <i className="bi bi-cash-stack fs-5"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Cash Register:</h6>
                      <p className="m-0 ">
                        The cash register utility allows for the seamless handling of financial transactions related to room charges, additional services, and miscellaneous expenses. It ensures accurate recording and management of payments.
                      </p>
                    </div>

                  </li>
                  {
                    location === '/intelli-pms' ? (
                        <li>
                          <div className="mt-2 float-end">
                            <a href="/ipms-features" className="btn btn-secondary">Learn More</a>
                          </div>
                        </li>
                    ) : ''
                  }
                  {
                    location === '/ipms-features' ? (
                        <>
                          <li>
                            <img className="sm-img-120" src="/img/ipms-images/web-feature1.png" title="" alt="" />
                          </li>
                          <li className="d-flex mb-3">
                            <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                              <i className="bi bi-cash-stack fs-5"/>
                            </div>
                            <div className="col ps-3">
                              <h6>Guest Refund:</h6>
                              <p className="m-0 ">
                                 The guest refund utility handles the process of issuing refunds to guests, whether due to cancellations, overpayments, or other applicable situations. It ensures accurate and timely reimbursement.

                              </p>
                            </div>
                          </li>
                          <li className="d-flex mb-3">
                            <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                              <i className="bi bi-cash-stack fs-5"/>
                            </div>
                            <div className="col ps-3">
                              <h6>Create or Edit Guest:</h6>
                              <p className="m-0 ">
                                This utility allows for the creation or modification of guest profiles, enabling staff to update guest information, preferences, loyalty program details, and other relevant data.

                              </p>
                            </div>
                          </li>
                          <li className="d-flex mb-3">
                            <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                              <i className="bi bi-cash-stack fs-5"/>
                            </div>
                            <div className="col ps-3">
                              <h6>Cash Management:</h6>
                              <p className="m-0 ">
                                The cash management utility provides tools and features to monitor and
                                control cash flow within the hotel, including cash reconciliation, cash position tracking, and generating financial reports.
                              </p>
                            </div>
                          </li>
                          <li className="d-flex mb-3">
                            <div className="icon-md rounded-circle text-white" style={{backgroundColor: '#CCD12C'}}>
                              <i className="bi bi-cash-stack fs-5"/>
                            </div>
                            <div className="col ps-3">
                              <h6>View/Create Payroll:</h6>
                              <p className="m-0 ">
                                The view/create payroll utility provides a platform to manage employee
                                payroll, including salary calculations, deductions, bonuses, and generating pay stubs. It streamlines the payroll process and ensures accurate compensation for employees
                              </p>
                            </div>
                          </li>
                          <li className="d-flex mb-3">
                            <img className="sm-img-120" src="/img/ipms-images/web-feature3.png" title="" alt="" />
                          </li>
                        </>
                    ) : ''
                  }

                </ul>
              </div>

            </div>
          </div>
        </section>
        {/*<section id="about-us" className="section bg-primary">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6  fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <h3  className="h1 bg-light-after after-50px pb-1 mb-3 text-white wow fadeInUp">ABOUT APP CLOCKIN</h3>
                <p className="lead text-white">
                  Tiptopgo is a leading online hotel booking platform that aims to revolutionize the way you book accommodations. Our mission is to provide a user-friendly and reliable platform that connects travelers with a diverse range of hotels worldwide. With Tiptopgo, you can discover, compare, and book the perfect hotel for your next trip with ease.
                </p>
                <ul className="list-type-03 mb-4 list-unstyled">
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Seamless Hotel Booking Experience: At Tiptopgo, we strive to make the hotel booking process seamless and hassle-free.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Extensive Selection of Hotels: you have access to an extensive selection of hotels across various destinations.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Real-time Availability and Instant Confirmation: Tiptopgo provides real-time availability updates, ensuring that you have the most up-to-date information on hotel rooms.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Convenient Booking Options: Tiptopgo offers both a mobile app and a web version, providing you with the flexibility to book accommodations from anywhere, anytime.
                  </li>
                </ul>
                <a  className="btn btn-secondary">
                  Learn More
                </a>
              </div>
              <div className="col-lg-6 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <img className="lg-img-140" src="/img/tiptopgo-images/single-pic.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>*/}
      </>
  );
};

export default IPMSWebFeatures;
