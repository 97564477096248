import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaDataDecorator = ({title, description}) => {
  localStorage.setItem('location', description);
  return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
      </div>
  );
};

MetaDataDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default MetaDataDecorator;
