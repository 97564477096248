import React from 'react';
import SubHeader from './Home/SubHeader';
import Header from './layouts/Header';
import MetaDataDecorator from '../utilities/MetaDataDecorator';
import OurProducts from './Home/OurProducts';
import AboutUsWhyUs from './Home/AboutUs-WhyUs';
import OurServices from './Home/OurServices';
// import Pricing from './Home/Pricing';
import ContactUsForm from './Home/ContactUsForm';
import Footer from './layouts/Footer';
import MarketingVideo from './EMS/MarketingVideo';

const Home = () => {
  //IMS-whitey
  return (
      <>
      <div className="wrapper">
        <Header headerStyle="navbar navbar-expand-lg navbar-dark" cssStyle="nav-link" logoImageDark="/img/ims-images/IMS-Bluey.png"  logoImageWhite="/img/ims-images/IMS-whitey.png"
                showProduct={true}/>
        <MetaDataDecorator description="Home" title="IMS | Home" />
       <main>
         <SubHeader />
         <OurProducts />
         <AboutUsWhyUs />
         <OurServices />
         <MarketingVideo backgroundImage="/img/ims-images/appy-layout.jpg"  videoUrl="https://www.youtube.com/embed/D_EGqYcNO2U?si=LNWygrQfJSRYJiy6" />
         {/*<Pricing />*/}
         <ContactUsForm />
       </main>
      </div>
        <Footer footerBg="bg-dark footer" logoImage="/img/ims-images/intelli-log.png" />
      </>
  );
};

export default Home;
