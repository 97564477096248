import React from 'react';

const IPmsServices = () => {
  return (
      <>
        <section className="section bg-gray-100">
          <div className="container">
            <div className="row section-heading justify-content-center">
              <div className="col-lg-6 text-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.05s">
                <h6 className="text-primary mb-4">What We provide</h6>
                <h3 className="h1 mb-3">Working to build a better Hospitality Business.</h3>
                <p className="lead m-0">With IPMS, everything from bookings to finances can be managed in one convenient place</p>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.05s">
                <div className="card">
                  <div className="card-body d-flex">
                    <div className="icon-lg bg-primary-soft text-primary rounded-3">
                      <i className="bi bi-laptop" />
                    </div>
                    <div className="col ps-3">
                      <h6>Booking</h6>
                      <p className="m-0">checkin guests, assign rooms, and provide keys or access cards, create reservation, Cash Register </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.05s">
                <div className="card">
                  <div className="card-body d-flex">
                    <div className="icon-lg bg-primary-soft text-primary rounded-3">
                      <i className="bi bi-globe"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Account</h6>
                      <p className="m-0">View/Create PayrollCreate or Edit Guest,Guest Refund, Petty Cash, Cash Management</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.05s">
                <div className="card">
                  <div className="card-body d-flex">
                    <div className="icon-lg bg-primary-soft text-primary rounded-3">
                      <i className="bi bi-briefcase" />
                    </div>
                    <div className="col ps-3">
                      <h6>Human Resources</h6>
                      <p className="m-0">Create Employee, employees control, Surcharge, employee dashboard, View Attendance</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <div className="card">
                  <div className="card-body d-flex">
                    <div className="icon-lg bg-primary-soft text-primary rounded-3">
                      <i className="bi bi-eye" />
                    </div>
                    <div className="col ps-3">
                      <h6>Reports</h6>
                      <p className="m-0">Gives management records of current guests, daily revenue, room stats, employees stats...</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <div className="card">
                  <div className="card-body d-flex">
                    <div className="icon-lg bg-primary-soft text-primary rounded-3">
                      <i className="bi bi-bootstrap"/>
                    </div>
                    <div className="col ps-3">
                      <h6>Guests</h6>
                      <p className="m-0">
                        Provides list of in-house guest, customer-services(Address guest inquiries, requests),Guest Payment
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <div className="card">
                  <div className="card-body d-flex">
                    <div className="icon-lg bg-primary-soft text-primary rounded-3">
                      <i className="bi bi-brush" />
                    </div>
                    <div className="col ps-3">
                      <h6>File Inspection System (FIS)</h6>
                      <p className="m-0">Staff Roaster, room inspection, cleaning, staff duty logs, creating and viewing events</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default IPmsServices;
