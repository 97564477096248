import React, {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {signUpSchema} from '../../utilities/models/forms-models';
import {useForm} from 'react-hook-form';
import axios from '../../utilities/axios';
import Alert from 'react-bootstrap/Alert';
import '../Registrations/formStyle.css';
import PersonalInfo from './PersonalInfo';
import BusinessInfo from './BusinessInfo';
import {NavLink} from 'react-router-dom';

const RegistrationForm = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [states, setAllStates] = useState([]);
  const [lgas, setAllLga] = useState([]);
  const [newLga, setNewLga] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [page, setPage] = useState(0);
  // const [businessName, setBusinessName] = useState('');
  // const [checkBusinessName, setCheckBusinessName] = useState(false);
  const [emsCode, setEmsCode] = useState('');
  // const [showForm, setShowForm] = useState(true);
  // const formTitles = ["Personal Info", "Business Info"];
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  let stateArr;

  const handlePasswordChange =(evnt)=>{
    setPasswordInput(evnt.target.value);
  }
  const togglePassword =()=>{
    if(passwordType==="password")
    {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }


  const { register, handleSubmit, formState, formState: { errors }, reset } = useForm({
    resolver: yupResolver(signUpSchema),
  });
  const { isSubmitting } = formState;

  const PageDisplay = () => {
    if (page === 0) {
      return <PersonalInfo register={register} errors={errors} />;
    } else {
      return <BusinessInfo register={register} errors={errors}/>;
    }
  }
  function scrollToAlertDiv(){
    window.scrollTo({top: 0, behavior: 'smooth'})
    // const divElement = document.getElementById('alertDiv');
    // divElement.scrollIntoView({ behavior: 'smooth' });
  }

  const createAccountDetails = async (data) => {
    // console.log('data', data);
    // const fullData = {
    //   ...data,
    //   businessName: businessName
    // }
    await spinnerHandler();
    const res = await axios.post('/employees/createWebEmployer', data);
    // console.log(res.data);
    setEmsCode(res.data?.emsCode)
    await  scrollToAlertDiv();
    HandleShowAlert();
    setShowAlert(true);
    // setShowForm(false);
    reset();
  };
  const HandleShowAlert = () => {
    return (
        <>
          {/*<Alert variant="success" onClose={() => setShowAlert(false)} >*/}
          {/*  Congratulations! You have Registered Successfully, click <a href='https://ems.intellims.com' target="_blank">here</a> to login and setup your business*/}
          {/*</Alert>*/}
          <div className="container" >
            <div id="tc-user" className="section-block bg-primary-gradient-dark">
              <div className="section-title bg-info-after after-50px mb-4"><h2 style={{color: 'gold'}}>CONGRATULATIONS</h2>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="section-text text-white">
                    <h4 className="text-white">You have completed your registrations</h4>
                    <h6 className="text-white">You Employment Management System(EMS) unique code is</h6>
                    <div>
                      {/*<i className="bi bi-check-circle-fill text-secondary me-2" />*/}
                      <h2 style={{color: 'gold'}}>{emsCode && emsCode || '123456'}</h2>
                    </div>
                    <p className="">We have so much to offer you.You can use this System to</p>
                    <div className="section-item">
                      <i className="bi bi-check-circle-fill text-secondary me-2" />
                      Look for a new job
                    </div>
                    <div className="section-item">
                      <i className="bi bi-check-circle-fill text-secondary me-2" />
                      Create and maintain your CV.
                    </div>
                    <div className="section-item">
                      <i className="bi bi-check-circle-fill text-secondary me-2" />
                      Send your CV to employers and track.
                      Click <a href="/" target="_blank" style={{color: 'gold', marginRight: '2px'}} > here</a>to know more
                    </div>

                    <div className="section-item">
                      <div>Click <a href="/intelli-ems" target="_blank" style={{color: 'gold', marginRight: '2px'}}>here</a>to watch our short video on</div>
                      <i className="bi bi-check-circle-fill text-secondary me-2" />
                      How to use the Sign in on EMS Web
                    </div>
                    <div className="section-item">
                      <i className="bi bi-check-circle-fill text-secondary me-2" />
                      How to retrive your password.
                    </div>
                    <div className="section-item">
                      <i className="bi bi-check-circle-fill text-secondary me-2" />
                      How to Open a business
                    </div>
                    <div className="section-item">
                      <i className="bi bi-check-circle-fill text-secondary me-2" />
                      How to create a Business
                    </div>
                    Ready, set, go click <a href="https://ems.intellims.com" target="_blank" style={{color: 'gold'}}>here</a> to log in to start improving your hustle
                  </div>
                </div>
                <div className="col-md-6 ">
                  <img className="" src="/img/ims-images/congrat-img/loyalty-image4.jpg" title="" alt="" />
                </div>
              </div>
            </div>

          </div>
          <>
            {/*<div>
            <h3>Welcome to IntelliEMS</h3>
            Visit <a href="/ems.intellims.com" target="_blank">intelliems</a> sign-in and setup your business
          </div>*/}
          </>
        </>
    );
  };

  function spinnerHandler() {
    // return promise that resolves after 2 seconds
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }

  const handleOnChangeState = (e) => {
    const stateId = e.target.value;
    stateArr = lgas.filter((sub) => sub.state_id === stateId);
    setNewLga(stateArr);
  }

  const checkEnterBusiness = async(e) => {
    // setBusinessName(e);
    const res = await axios.get(`business/checkBusinessExistence?businessName=${e}`);
    // console.log(res, businessName)
    // setCheckBusinessName(res.data);
  }

  useEffect( () => {
    async function getAllBusinessTypes() {
      const res = await axios.get(`/general/getIndustries`);
      setBusinessTypes(res.data)
    }
    async function  getAllLga() {
      const res = await axios.get(`/general/local_govt`);
      setAllLga(res.data)
    }

    async function getAllStates () {
      const res = await axios.get(`/general/states`);
      setAllStates(res.data)
    }
    async function getAllQualifications () {
      const res = await axios.get(`/general/getEmployeeQualifications`);
      setQualification(res.data)
    }

    getAllBusinessTypes().then(r => r);
    getAllLga().then(r => r);
    getAllStates().then(r => r);
    getAllQualifications().then(r => r);
  },[]);

  const SignUpForm = () => {
    return (
        <>
          <section className="section bg-gray-100">
            <div className="container">
              <div className="row section-heading justify-content-center text-center wow fadeInUp" data-wow-duration=".4s"
                   data-wow-delay="0.1s">
                <div className="col-lg-8 col-xl-6">
                  <h3 className="h1 bg-primary-after after-50px pb-1 mb-1">let's start working together</h3>
                  <div className="lead">
                    Sign up today to enjoy our awesome product
                  </div>
                </div>
              </div>
              <div className="row justify-content-center gy-4" >
                <div className="col-md-12 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.1s">
                  <div className="bg-white shadow p-4 p-lg-6 border rounded-3">
                    {/*<div className="pb-4 text-center">
                      <h3 className="mb-2 text-primary">Create your account</h3>
                      <>{showAlert && HandleShowAlert()}</>
                    </div>*/}
                    <form onSubmit={handleSubmit(createAccountDetails)}
                          >
                      <fieldset className=""><legend>Personal Information</legend>

                        <div className="row">


                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="form-label">First Name<span
                                  className="compulsory">*</span></label>
                              <input id="contact-name" type="text" name="name"
                                     placeholder="Enter First Name" {...register("firstName")}
                                     className="form-control form-control-has-validation form-control-last-child" />
                              <div className="redText">{errors.firstName?.message}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="form-label">Last Name<span
                                  className="compulsory">*</span></label>
                              <input id="contact-name" type="text" name="lastName"  placeholder="Enter Last Name" {...register("lastName")}
                                     className="form-control form-control-has-validation form-control-last-child" />
                              <div className="redText">{errors.lastName?.message}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="form-label">Other Name</label>
                              <input id="contact-name" type="text" name="otherName" placeholder="Enter User Name" {...register("otherName")}
                                     className="form-control form-control-has-validation form-control-last-child" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="form-label">Email address<span
                                  className="compulsory">*</span></label>
                              <input id="contact-email" type="email" name="email"
                                     placeholder="email@gmail.com"
                                     {...register("email")}
                                     className="form-control form-control-has-validation form-control-last-child" />
                              <div className="redText">{errors.email?.message}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="form-label">Gender<span
                                  className="compulsory">*</span></label>
                              <select name="gender" className="form-control" {...register("gender")}>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="preferNotToSpecify">Prefer not to specify</option>
                              </select>
                              <div className="redText">{errors.gender?.message}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-label">Phone Number <span
                                  className="compulsory">*</span></label>
                              <div className="input-group mb-3">
                                <div className="input-group-addon">
                                  <span className="input-group-text bg-primary text-white" id="basic-addon1">+234</span>
                                </div>
                                <input className="form-control" name="phoneNumber" placeholder="Enter Phone Number" {...register("phoneNumber")} />
                              </div>
                              <div className="redText">{errors.phoneNumber?.message}</div>
                            </div>

                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-label">Date Of Birth <span
                                  className="compulsory">*</span></label>
                              <div className="mb-3">

                                <input className="form-control" type="date" name="dateOfBirth" placeholder="Enter Phone Number" {...register("dateOfBirth")} />
                              </div>
                              <div className="redText">{errors.dateOfBirth?.message}</div>
                            </div>

                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="form-label">Marital Status<span
                                  className="compulsory">*</span></label>
                              <select name="maritalStatus" className="form-control" {...register("maritalStatus")}>
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                                <option value="divorced">Divorced</option>
                                <option value="widow">Widow</option>
                                <option value="widower">Widower</option>
                              </select>
                              <div className="redText">{errors.maritalStatus?.message}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="form-label">Select Qualifications <span
                                  className="compulsory">*</span></label>
                              <select className="form-control" name="qualification"  {...register("qualification")}>
                                <option value='' disabled>Select Qualifications</option>
                                {
                                  qualification && qualification.map((e) => {
                                    return (<option key={e.qualificationId} value={e.qualificationId}>{e.qualification}</option> )
                                  })
                                }
                              </select>
                              <div className="redText">{errors.qualification?.message}</div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="form-label">Address<span
                                  className="compulsory">*</span></label>
                              <textarea className="form-control form-control-has-validation form-control-last-child"
                                        id="contact-message" rows="3"
                                        placeholder="Hi there, I would like to ..."
                                        name="userAddress" {...register("userAddress")}/>
                              <div className="redText">{errors.userAddress?.message}</div>
                            </div>
                          </div>
                          {/*<div className="col-sm-3">
                            <div className="input-group my-4 mx-4">
                              <input type={passwordType} onChange={handlePasswordChange} value={passwordInput}
                                     name="password" className="form-control" placeholder="Password"/>
                              <div className="input-group-btn">
                                <button className="btn btn-outline-primary" onClick={togglePassword}>
                                  {passwordType === "password" ? <i className="bi bi-eye-slash"/> :
                                      <i className="bi bi-eye"/>}
                                </button>
                              </div>
                            </div>

                          </div>*/}
                          <div className="form-group  col-md-6 mb-3 mt-3">
                            <label className="form-label text-primary">Password<span
                                className="compulsory">*</span></label>
                            <div className="input-group">
                              <input type={passwordType} onChange={handlePasswordChange} name="password" className="form-control" placeholder="***********"
                                     {...register("password")}/>
                              <div className="input-group-btn">
                                <button className="btn btn-outline-primary" onClick={togglePassword}>
                                  {passwordType === "password" ? <i className="bi bi-eye-slash"/> :
                                      <i className="bi bi-eye"/>}
                                </button>
                              </div>
                            </div>
                            {/*<div className="input-group">
                              <input name="password" className="form-control" placeholder="***********"
                                     {...register("password")}/>

                            </div>*/}
                            <div className="redText">{errors.password?.message}</div>
                          </div>
                          <div className="form-group col-md-6 mb-3 mt-3">
                            <label className="form-label text-primary">Confirm Password<span
                                className="compulsory">*</span></label>
                            <div className="input-group">
                              <input type={passwordType} onChange={handlePasswordChange} name="confirmPassword" className="form-control" placeholder="***********"
                                     {...register("confirmPassword")}/>
                              <div className="input-group-btn">
                                <button className="btn btn-outline-primary" onClick={togglePassword}>
                                  {passwordType === "password" ? <i className="bi bi-eye-slash"/> :
                                      <i className="bi bi-eye"/>}
                                </button>
                              </div>
                            </div>

                            <div className="redText">{errors.confirmPassword && "Password Mismatch"}</div>
                          </div>

                          <div className="row"><legend className="">Business Information</legend>
                            <div className="col-md-12">
                              <div className="form-group mb-3">
                                <label className="form-label text-primary">Business Name<span
                                    className="compulsory">*</span></label>
                                <input type="text" name="businessName" className="form-control"
                                       placeholder="Enter Business Name"  {...register("businessName")}
                                />
                                <div className="redText">{errors.businessName?.message}</div>
                                  {/* <input type="text" name="businessName" className="form-control"
                                       placeholder="Enter Business Name" onChange={(event) => checkEnterBusiness(event.target.value)}
                                />
                                <div className="redText">{checkBusinessName && <span>Business name already taken</span>}</div>*/}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label className="form-label text-primary">Select Business Type <span
                                    className="compulsory">*</span></label>
                                <select className="form-control" name="industry"  {...register("industry")}>
                                  <option value='' disabled>Select Business Type</option>
                                  {
                                    businessTypes && businessTypes.map((e) => {
                                      return (<option key={e.industryId} value={e.industryId}>{e.industry}</option> )
                                    })
                                  }
                                </select>
                                <div className="redText">{errors.industry?.message}</div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label className="form-label text-primary">Business CAC Number<span
                                    className="compulsory">*</span></label>
                                <input type="text" name="cacNumber" className="form-control"
                                       placeholder="Enter CAC Number" {...register("cacNumber")}
                                />
                                <div className="redText">{errors.cacNumber?.message}</div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label className="form-label text-primary">Business Email<span
                                    className="compulsory">*</span></label>
                                <input type="text" name="businessEmaile" className="form-control"
                                       placeholder="Enter business Email" {...register("businessEmail")}
                                />
                                <div className="redText">{errors.businessEmail?.message}</div>
                              </div>
                            </div>
                            <div className="col-md-6">

                              <label className="form-label text-primary">Business Phone Number <span
                                  className="compulsory">*</span></label>
                              <div className="input-group mb-3">
                                <div className="input-group-addon">
                                  <span className="input-group-text bg-primary text-white" id="basic-addon1">+234</span>
                                </div>
                                <input type="text" name="businessPhone" className="form-control"
                                       placeholder="Enter Business Phone"  {...register("businessPhone")} />
                              </div>
                              <div className="redText">{errors.businessPhone?.message}</div>
                              {/*<div className="form-group mb-3">
                                <label className="form-label text-primary">Business Phone<span
                                    className="compulsory">*</span></label>
                                <input type="text" name="businessPhone" className="form-control"
                                       placeholder="Enter Business Phone"  {...register("businessPhone")}
                                />
                                <div className="redText">{errors.businessPhone?.message}</div>
                              </div>*/}
                            </div>
                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label  className="form-label text-primary">Select State <span
                                    className="compulsory">*</span></label>
                                <select className="form-control" name="state" {...register("state")}
                                        onChange={(e) => handleOnChangeState(e)}>
                                  <option value="" disabled>Select State</option>
                                  {
                                    states.map((e) => {
                                      return (<option key={e.id} value={e.id}>{e.name}</option>)
                                    })
                                  }
                                </select>
                              </div>
                              <div className="redText">{errors.state?.message}</div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label  className="form-label text-primary">Select LGA <span
                                    className="compulsory">*</span></label>
                                <select className="form-control" name="localGovt" {...register("localGovt")}>
                                  <option value="" disabled>
                                    Select local Govt...
                                  </option>
                                  {
                                    newLga.map((e) => {
                                      return (<option key={e.id} value={e.id}>{e.name}</option>)
                                    })
                                  }
                                </select>
                              </div>
                              <div className="redText">{errors.localGovt?.message}</div>
                            </div>
                            <div className="col-md-12">
                              <label className="form-label text-primary">About Business<span
                                  className="compulsory">*</span></label>
                              <textarea className="form-control" name="about" {...register("about")}>

                        </textarea>
                              <div className="redText">{errors.about?.message}</div>
                            </div>
                            <div className="col-md-12">
                              <label className="form-label text-primary">Address<span
                                  className="compulsory">*</span></label>
                              <textarea className="form-control" name="address" {...register('address')}>

                        </textarea>
                              <div className="redText">{errors.address?.message}</div>
                            </div>
                            <div className="col-md-12 pt-2">
                              <button disabled={isSubmitting} className="btn btn-primary text-white w-100" type="submit"> {isSubmitting && <span className="spinner-grow spinner-grow-sm" />} {isSubmitting&& isSubmitting? "Processing...":"Create My Account"} </button>

                            </div>

                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }
  return (
      <>{showAlert ? <HandleShowAlert /> : <SignUpForm />}</>
  );
};

export default RegistrationForm;
