import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {contactSchema} from '../../utilities/models/forms-models';
import axios from '../../utilities/axios';
import Alert from 'react-bootstrap/Alert';
const ContactUsForm = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { register, handleSubmit, formState: {errors }, reset} = useForm({
    resolver: yupResolver(contactSchema),
  });

  const sendMessage = async (data) => {
    // console.log(data);
    setProcessing(true);
    const res = await axios.post('/general/createContactUs', data);
    // console.log(res.data);
    handleShowAlert();
    setShowAlert(true);
    setProcessing(false)
    reset()
  };
  const handleShowAlert = () => {
    return (
        <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
          Your Message is Received, We Will Contact You Shortly. Thanks
        </Alert>
    );
  };
  return (
      <>
        <section className="section bg-gray-100">
          <div className="container">
            <div className="row section-heading justify-content-center text-center wow fadeInUp" data-wow-duration=".4s"
                 data-wow-delay="0.1s">
              <div className="col-lg-8 col-xl-6">
                <h3 className="h1 bg-primary-after after-50px pb-3 mb-3">let's start working together</h3>
                <div className="lead">
                  Our Support team are available 24/7 to receive your complains and feedback.
                </div>
              </div>
            </div>
            <div className="row justify-content-center gy-4">
              <div className="col-md-7 col-lg-7 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.1s">
                <div className="bg-white shadow p-4 p-lg-6 border rounded-3">
                  <div className="pb-4 text-center">
                    <h3 className="mb-2 text-primary">Contact Us</h3>
                    <span>{showAlert && handleShowAlert()}</span>

                  </div>
                  <form onSubmit={handleSubmit(sendMessage)}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="form-label fw-bold">First Name</label>
                          <input id="contact-name" type="text"  name="userName" className="form-control"
                                 placeholder="Enter First Name" {...register("firstName")} />
                          <div className="redText">{errors.firstName?.message}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="form-label fw-bold">Last Name</label>
                          <input id="contact-name" type="text"  name="userName" className="form-control"
                                 placeholder="Enter Last Name" {...register("lastName")} />
                          <div className="redText">{errors.lastName?.message}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="form-label fw-bold">Email address</label>
                          <input id="contact-email" type="email" name="email" className="form-control" placeholder="email@gmail.com"
                                 {...register("email")}/>
                          <div className="redText">{errors.email?.message}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="form-label fw-bold">Phone Number</label>
                          <input type="text" name="phoneNumber" className="form-control"
                                 placeholder="Enter Phone Number" {...register("phoneNumber")}
                          />
                          <div className="redText">{errors.phoneNumber?.message}</div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label className="form-label fw-bold">Select Product/Service</label>
                          <select name="gender" className="form-control" {...register("product")}>
                            <option value="intellims">Intellims</option>
                            <option value="intelliems">IntelliEms</option>
                            <option value="App-ClockIn">App-ClockIn</option>
                            <option value="General">General</option>
                          </select>
                          {/*<input type="text" name="subject" className="form-control"
                                 placeholder="Enter Message Title" {...register("product")}
                          />*/}
                          <div className="redText">{errors.product?.message}</div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label className="form-label fw-bold">Subject</label>

                          <input type="text" name="subject" className="form-control"
                                 placeholder="Enter Message Title" {...register("subject")}
                          />
                          <div className="redText">{errors.subject?.message}</div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label className="form-label fw-bold">Message</label>
                        <textarea className="form-control" name="userMessage" {...register("userMessage")} rows="5">

                        </textarea>
                        <div className="redText">{errors.userMessage?.message}</div>
                      </div>

                      <div className="col-md-12 pt-2">
                        <button className="btn btn-primary" type="submit" disabled={processing}>{processing ? "Sending..." : "Send Message"}</button>
                        <div className="snackbars" id="form-output-global" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.1s">
                <div className="bg-white p-2 shadow rounded-3 h-100 ms-xl-6">
                  <div className="py-5 px-4 bg-primary h-100">
                    <h5 className="h6 text-white mb-2">Our address info</h5>
                    <p className="text-white-85 mb-2">3C Durban Street, Wuse II, Abuja.</p>
                    <p className="text-white-85">5 Amichi Close, Victoria Island, Lagos.</p>
                    <h5 className="h6 text-white mb-2">Our contact info</h5>
                    {/*<p className="mb-2"><a className="link-light"
                                           href="mailto:support@ims.com">support@ims.com</a></p>*/}
                    <p className="m-0"><a className="link-light" href="tel:+234814906996">+2348149069961, +2347047208665</a></p>
                    <h5 className="h6 text-white mb-3 mt-5">Our contact info</h5>
                    <div className="nav">
                      <a className="icon-sm bg-light rounded-circle me-2" href="https://www.facebook.com/Intelligence-Management-Systems-Intellims-101137772568240/"
                         target="_blank">
                        <i className="fab fa-facebook-f"/>
                      </a>
                      <a className="icon-sm bg-light rounded-circle me-2" href="https://twitter.com/intell_ims?t=Czf5ILnCu1u25Y3hDKjJfQ&s=09" target="_blank">
                        <i className="fab fa-twitter"/>
                      </a>
                      <a className="icon-sm bg-light rounded-circle me-2" href="https://www.instagram.com/p/CcFscsTje0c/?igshid=MjU0Y2ZlMmY=" target="_blank">
                        <i className="fab fa-instagram"/>
                      </a>
                      <a className="icon-sm bg-light rounded-circle me-2" href="http://www.linkedin.com/in/intellims-limited-a57302237" target="_blank">
                        <i className="fab fa-linkedin-in"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default ContactUsForm;
