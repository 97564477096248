import  * as Yup from 'yup';

export const signUpSchema = Yup.object().shape({
  // userName: Yup.string().required("user name is a required field"),
  email: Yup.string().email().required("email is a required field"),
  password: Yup.string().min(4).max(10).required(),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null]),
  // termsConditions: Yup.string().required(),
  firstName: Yup.string().required("first name is a required field"),
  lastName: Yup.string().required("last name is a required field"),
  otherName: Yup.string(),
  gender:  Yup.string().oneOf(["male", "female", "preferNotToSpecify"])
      .required(),
  phoneNumber: Yup.string().min(10).max(10).required("phone required"),
  address: Yup.string().min(5).required("Address is required"),
  userAddress: Yup.string().min(5).required("Your Address ius required"),
  localGovt: Yup.string().required(),
  state: Yup.string().required(),
  cacNumber: Yup.string().required(),
  industry: Yup.string().required("industry type is required"),
  businessName: Yup.string().required("business name is required"),
  about: Yup.string().required(),
  businessEmail: Yup.string().required("business email is required"),
  businessPhone: Yup.string().required("business line is required"),
  maritalStatus:  Yup.string().oneOf(["single", "married", "divorced", "widow", "widower"])
      .required(),
  qualification: Yup.string().required("qualification is required"),
  dateOfBirth: Yup.string().required("date of birth is required"),
});

export const contactSchema = Yup.object().shape({
  // userName: Yup.string().required("user name is a required field"),
  email: Yup.string().email().required("email is a required field"),
  firstName: Yup.string().required("first name is a required field"),
  lastName: Yup.string().required("last name is a required field"),
  phoneNumber: Yup.string().min(11).max(11).required("phone is required"),
  userMessage: Yup.string().min(5).required("message is required"),
  subject: Yup.string().min(4).required("message title is required"),
  product:  Yup.string().oneOf(["intellims", "intelliems", "App-ClockIn", "General"])
      .required("product type is required field"),
});
