import React from 'react';
import {NavLink} from 'react-router-dom';

const AboutUsWhyUs = () => {
  return (
      <>
        <section id="about-us" className="section bg-primary">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-7 wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <h3  className="h1 bg-light-after after-50px pb-3 mb-3 text-white wow fadeInUp">ABOUT US</h3>
                <p className="lead text-white">
                  We are a Nigerian based company with
                  clients all over the world. We believe that
                  Experience combined with Energy makes a
                  team complete
                </p>
                <ul className="list-type-03 mb-4 list-unstyled">
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Our management team
                    comprises of members with almost a Decade
                    of Industry experience in various fields,
                    combined with cores of software & HR
                    industry knowledge.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                  Currently with competent direct/indirect work
                    force,combined with continuously
                    increasing network of numerous
                    partners,we have the capacity to
                    provide solutions all over Nigeria.
                  </li>
                  <li className="d-flex py-1 text-white"><i className="bi bi-check-circle-fill text-secondary me-2" />
                  Acquited with modern technology
                  </li>
                </ul>
                <NavLink to="/about-us" className="btn btn-secondary">
                  Learn More
                </NavLink>
              </div>
              <div className="col-lg-5 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <img src="/img/ims-images/about-us.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="row align-items-center justify-content-between flex-row-reverse">
              <div className="col-md-6 my-1 wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <h3  className="h1 bg-light-after after-50px pb-3 mb-3 wow fadeInUp">Why Work With Us</h3>
                <p className="lead">
                  Started in the year 2008 with a vision to develop a complete management solution that provide end to
                  end solution, we believe that we achieved considerable success in Attendance & Payroll solution,
                  which is completely integrated with some of the well-known Biometric brands.
                </p>
                <ul className="list-type-03 mb-4 list-unstyled">
                  <li className="d-flex py-1"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    With more than 8years in the Attendance domain, we have a large customer base who generate attendance
                    for a significant amount of employees each month. And we have a documented list of satisfied payroll
                    customers who have been able to automate their calculations and processes, with our combined single-platform solution.
                  </li>
                  <li className="d-flex py-1"><i className="bi bi-check-circle-fill text-secondary me-2" />
                    Taking into consideration the varieties of customers and their varying requirements, number,
                    and budget for their workforce, we have developed solutions on all three platforms-Desktop, web, and Cloud; for each preference.
                  </li>
                  <li className="d-flex py-1"><i className="bi bi-check-circle-fill text-secondary me-2" />Our Staff are equipped
                    with the latest technologies and tools.
                  </li>
                </ul>
                <a className="btn btn-primary" >Learn More</a>
              </div>
              <div className="col-md-6 my-1 wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.1s">
                <img src="/img/ims-images/why-work-with-us.png" title="" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

export default AboutUsWhyUs;
