import React from 'react';
import {  NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './layout.css'


const Header = ({headerStyle, cssStyle, logoImageDark, logoImageWhite, showProduct }) => {
  // console.log(window.location.pathname);
  const location = window.location.pathname;
  /*const hideToggleBar = () => {
    document.getElementById('navbarSupportedContent').style.display = 'none';
    // document.getElementById('navbarSupportedContent').style.display = 'show';
  }*/

  function scrollToProductDiv(){
    const divElement = document.getElementById('products');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }

  function scrollToPricingDiv(){
    const divElement = document.getElementById('pricing');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }
  function scrollToFeaturesDiv(){
    const divElement = document.getElementById('features');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }
  function scrollToAboutDiv(){
    const divElement = document.getElementById('about-us');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }
  function scrollToServiceDiv(){
    const divElement = document.getElementById('services');
    divElement.scrollIntoView({ behavior: 'smooth' });
    // hideToggleBar();
  }
  function scrollToMediaDiv(){
    const divElement = document.getElementById('media-section');
    divElement.scrollIntoView({ behavior: 'smooth' });
    // hideToggleBar();
  }
  return (
      <>
        <header className="header-main header-dark fixed-top">
          <nav className={headerStyle}>
            <div className="container">
              <a className="navbar-brand" >
                {/*<h2 className="logo-light link-white">INTELLiMS</h2>*/}
             {/*   <img className="logo-dark" src="../../assets/img/logo.svg /img/ims-images/web-icon2.png" title="" alt="" />
                  <img className="logo-light" src="../../assets/img/logo-light.svg" title="" alt="" />*/}
                <img className="logo-dark"  src={logoImageDark} title="" alt="" />
                  <img className="logo-light" src={logoImageWhite} title="" alt=""/>
              </a>

              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                      aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav m-auto">
                  <li className="nav-item">
                    <NavLink to="/"  className={cssStyle}>
                      Home
                    </NavLink>

                  </li>

                  {
                    showProduct && showProduct ? (
                        <>
                          <li className="nav-item">
                            <NavLink to="/#products" className={cssStyle}  onClick={scrollToProductDiv}>
                              Products
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink to='/#' className={cssStyle} onClick={scrollToServiceDiv}>
                              Services
                            </NavLink>
                          </li>

                        </>
                    ) : (
                    <li className="nav-item">
                    <NavLink to={`${location}#features`} className={cssStyle}  onClick={scrollToFeaturesDiv}>
                      Features
                    </NavLink>
                    </li>
                    )
                  }

                  {/*<li className="nav-item">
                    <NavLink to='/#' className={cssStyle} onClick={scrollToPricingDiv}>
                      Pricing media-section
                    </NavLink>
                  </li>*/}
                   <li className="nav-item">
                    <NavLink to={location === '/contact-us' ? '/' : `${location}/#media-section`} className={cssStyle} onClick={scrollToMediaDiv}>
                      Media
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={location === '/' ? `${location}/#about-us` : '/about-us'} className={cssStyle} onClick={scrollToAboutDiv}>
                      About
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/contact-us" className={cssStyle}>
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div id="createAccountBtn" className="nav flex-column flex-lg-row d-none d-lg-flex">
                <ul className="navbar-nav ms-auto align-items-center">
                  {
                    location && location ==='/intelli-ems' ? (
                        <li className="nav-item">
                          <NavLink to="/create-account"  className="btn btn-md btn-secondary mb-0 ms-2 text-nowrap rounded">
                            Sign up
                          </NavLink>
                        </li>
                    ): ''
                  }
                  {
                    location && location ==='/intelli-ems' ? (
                        <li className="nav-item">
                          <a href="https://ems.intellims.com" className="btn btn-md btn-secondary mb-0 ms-2 text-nowrap rounded" target="_blank">
                            Login
                          </a>
                        </li>
                    ) : ''
                  }
                  {
                    location && location === '/tiptopgo-app' ? (
                        <li className="nav-item">
                          <a href='https://www.tiptopgo.com' target='_blank'  className="btn btn-md mb-0 ms-2 text-nowrap rounded text-white" style={{backgroundColor: '#CCD12C'}}>
                            Book Now
                          </a>
                        </li>
                    ): ''
                  }
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </>
  );
};

Header.propTypes = {
  headerStyle: PropTypes.string,
  cssStyle: PropTypes.string,
  logoImage: PropTypes.string,
  headerName: PropTypes.string,
  showProduct: PropTypes.bool,
}

export default Header;
